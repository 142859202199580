<template>
    <div>
         <Label :text="text"/>
            <v-text-field 
            outlined
            dense
            :placeholder="placeholder"
            v-model="data"
             :error-messages="error"
             @focusout="focusout"
            />
    </div>
</template>


<script>
import Label from "@/app/components/atoms/Label.vue"

export default { 
    props:["text", "placeholder","value","error_message"],
    data(){
        return {
             field:{
                 
             }
        }
    },
    components:{
        Label
    },
    methods:{
        focusout(){
            if(this.data.length<1){
              this.error=`the field ${this.text.toLowerCase()} is obligatory`
            }else{
                this.error=""
            }
        }
    },
    computed:{
        data:{
            get(){
                return this.value
            },
            set(value){
                this.$emit('update:value',value)
            }
        },
        error:{
            get(){
                return this.error_message
            },
            set(value){
                this.$emit('update:error_message',value)
            }
        }
    },
    watch:{
        data:function(value){
             if(value.length<1){
              this.error=`the field ${this.text.toLowerCase()} is obligatory`
            }else{
                this.error=""
            }
        }
    }

}
</script>
