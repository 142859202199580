<template>
<div>
    <div class="font-label">Time</div>
 <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            @focusout="focusout"
              outlined
              dense
              placeholder="00:00"
            readonly
            :error-messages="error"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time"
          full-width
          @click:minute="$refs.menu.save(time)"
        >
         <v-spacer></v-spacer>

                   <v-btn 
                   text 
                   color="primary"
                     @click="$refs.menu.save('')">
                            Clear
                        </v-btn>
        </v-time-picker>
          
      </v-menu>
</div>
   
</template>

<script>
export default {
  props:["value","error_message","obligatory"],
    data(){
        return{
            menu2: false,
            modal2: false,
        }
    },
    methods:{
      focusout(){
        if(this.time.length<1 && this.obligatory){
          this.error="the field time is obligatory"
        }else{
          this.error=""
        }
      }
    },
    computed:{
      time:{
        get(){
          return this.value
        },
        set(value){
          this.$emit('update:value',value)
        }
      },
      error:{
        get(){
          return this.error_message
        },
        set(value){
          this.$emit('update:error_message',value)
        }
      }
    },
    watch:{
      time:function(value){
         if(value.length<1 && this.obligatory){
          this.error="the field time is obligatory"
        }else{
          this.error=""
        }
      }
    }

}
</script>

<style scoped>
.font-label{
    font-size: 16px;
    margin-bottom: 8px;

}


</style>
