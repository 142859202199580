import { EventDomain } from "../../core/entities/EventDomain";
import { EventRepository } from "../../core/repository/EventRepository";
import { API, graphqlOperation } from 'aws-amplify';
import {createEvent,deleteEvent, updateEvent}  from "../../../../graphql/mutations"
import * as queries from "../../../../graphql/queries"
import {Storage } from 'aws-amplify';
import awsexports from "../../../../aws-exports"


export class EventSourceGraphql implements EventRepository{
  
    
    async save(event: EventDomain): Promise<any> {
     //   const result = event

     const result=  await  API.graphql(graphqlOperation(createEvent,{input:{
            id:event.id,
            name:event.name,
            organization:event.organization,
            time_zone:event.time_zone,
            active_from:event.active_from,
            active_until:event.active_until,
            webhook_url:event.webhookUrl,
            skip_disclaimer:event.disclaimer,
            social_networks:event.social_networks,
            default_caption:event.default_caption,
            final_message:event.final_message,
            backgrounds:event.backgrounds,
            frames:event.frames,
            deleted:false,
            stickers:event.stickers
        }}))
       

        return result
        
    }

    async edit(id:string,event: EventDomain):Promise<any>{

        const result = await API.graphql(graphqlOperation(updateEvent,{
            input:{
                id:id,
                name:event.name,
                organization:event.organization,
                time_zone:event.time_zone,
                active_from:event.active_from,
                active_until:event.active_until,
                webhook_url:event.webhookUrl,
                skip_disclaimer:event.disclaimer,
                social_networks:event.social_networks,
                default_caption:event.default_caption,
                final_message:event.final_message
            }
        }))

        return result

    }

    async search(id:string):Promise<any>{
      // console.log(String(id),"aqui")
       return await API.graphql({ query: queries.getEvent, variables: { id:id},authMode:"API_KEY"});

    }
   async searchAll(user:any): Promise<any> {
       const result= await fetch("https://o8bynfbb71.execute-api.us-east-1.amazonaws.com/events/"+user)
       //const result= await fetch("https://0npozxiaai.execute-api.us-east-1.amazonaws.com/events/"+user)

       const data =  await result.json()
       return data
    }

    async delete(id:string):Promise<any>{
        return await  API.graphql(graphqlOperation(updateEvent,{input:{id,deleted:true}}))
    }

    async deleteImage(id: string, key: string): Promise<any> {

        const result:any = await API.graphql({ query: queries.getEvent, variables: { id:id,deleted:true}});
        const name_gallery = key.split("/")[1]
        const gallery_images =   result.data.getEvent[`${name_gallery}`]
        const data = gallery_images.filter((el:any)=>el.key!==key)
        
         console.log(name_gallery)

         let resultDelete

         if(name_gallery==="backgrounds"){
             resultDelete = await API.graphql(graphqlOperation(updateEvent,{
               input:{
                   id:id,
                   backgrounds:data
               }
           }))
         }else if(name_gallery==="frames"){
            resultDelete = await API.graphql(graphqlOperation(updateEvent,{
                input:{
                    id:id,
                    frames:data
                }
            }))

         }else if(name_gallery === "stickers"){
            resultDelete = await API.graphql(graphqlOperation(updateEvent,{
                input:{
                    id:id,
                    stickers:data
                }
            }))
         }

         await   Storage.remove(key)


       

        return    resultDelete
       

      //  throw new Error("Method not implemented.");
    }

    async addImage(id: string, key: string,value:any): Promise<any> {

        const result:any = await API.graphql({ query: queries.getEvent, variables: { id:id}});
        const name_gallery = key.split("/")[1]
        console.log(name_gallery)
        const gallery_images =   result.data.getEvent[`${name_gallery}`]

        const data= gallery_images.concat({
                bucket:awsexports.aws_user_files_s3_bucket,
                region:awsexports.aws_user_files_s3_bucket_region,
                key:key
            })

      await Storage.put(key,value)

       let resultDelete

         if(name_gallery==="backgrounds"){
             resultDelete = await API.graphql(graphqlOperation(updateEvent,{
               input:{
                   id:id,
                   backgrounds:data
               }
           }))
         }else if(name_gallery==="frames"){
            resultDelete = await API.graphql(graphqlOperation(updateEvent,{
                input:{
                    id:id,
                    frames:data
                }
            }))

         }else if(name_gallery === "stickers"){
            resultDelete = await API.graphql(graphqlOperation(updateEvent,{
                input:{
                    id:id,
                    stickers:data
                }
            }))
         }

           return resultDelete
    }

}