<template>
 <div class="absolute-position color-background-modal w-screen h-screen flex justify-center align-center">
 <div style="background-color:white" class="w-96 px-4 py-2 rounded" >
      <v-row  justify="center">
               <v-col cols="auto">
                   <v-progress-circular
           indeterminate
         color="#EEA22F"
        ></v-progress-circular>
               </v-col>
        </v-row>
   </div>
</div>
</template>
<script>
export default {
    
}
</script>
<style scoped>


.absolute-position{
    position: fixed;
    top:0;
    left: 0;
    z-index: 1;

}
.color-background-modal{
    transition: all ease 0.5s;
    background-color: rgba(0, 0, 0, 0.4);
   

}

</style>