import { Background } from "../../entities/Background";
import { EventDomain } from "../../entities/EventDomain";
import { Frame } from "../../entities/Frame";
import { Sticker } from "../../entities/Sticker";
import { EventImageRepository } from "../../repository/EventImageRepository";
import { EventRepository } from "../../repository/EventRepository";
import awsexports from "../../../../../aws-exports"


export const CreateEvent = (repository:EventRepository,StorageImageRepository:EventImageRepository)=>
async (params:{
    name:string,
    organization:string,
    active_from:{
        date:string,
        time:string
    },
    active_until:{
        date:string,
        time:string
    },
    default_caption:string,
    final_message:string,
    time_zone:string,
    webhookUrl:string,
    disclaimer:boolean,
    social_networks:string[],
    backgrounds:any[],
    frames:any[],
    stickers:any[]
})=>{

   const event = EventDomain.create({
        name:params.name,
        organization:params.organization,
        active_from:{
            date:params.active_from.date,
            time:params.active_from.time
        },
        active_until:{
            date:params.active_until.date,
            time:params.active_until.time
        },
        default_caption:params.default_caption,
        final_message:params.final_message,

        time_zone:params.time_zone,
        social_networks:params.social_networks,
        webhookUrl:params.webhookUrl,
        disclaimer:params.disclaimer

    })
    
   //event.verifyArrayIsGreaterThanOne({backgrounds:params.backgrounds,frames:params.frames,stickers:params.stickers})

   event.verify_if_have_missing_files()
    /**Backgorunds */


    try{
        for (const [index,value] of params.backgrounds.entries()){

            const key=Background.prepareImageKeyForStorage({
                filename:params.backgrounds[index].name,
                key:event.id
            })
    
            await  StorageImageRepository.store(key,value)
    
            event.addBackground( Background.create({
                bucket:awsexports.aws_user_files_s3_bucket,
                region:awsexports.aws_user_files_s3_bucket_region,
                key:key
            }))
    
        }
    
          /**Frames **/
    
        for (const [index,value] of params.frames.entries()){
    
            const key=Frame.prepareImageKeyForStorage({
                filename:params.frames[index].name,
                key:event.id
            })
        
            await  StorageImageRepository.store(key,value)
            event.addFrame(Frame.create({
                bucket:awsexports.aws_user_files_s3_bucket,
                region:awsexports.aws_user_files_s3_bucket_region,
                key:key
            }))
    
            
        }
    
          /**Stickers **/
    
        for (const [index,value] of params.stickers.entries()){
    
            const key=Sticker.prepareImageKeyForStorage({
                filename:params.stickers[index].name,
                key:event.id
            })
    
            await  StorageImageRepository.store(key,value)
    
            event.addStickers(Sticker.create({
                bucket:awsexports.aws_user_files_s3_bucket,
                region:awsexports.aws_user_files_s3_bucket_region,
                key:key
            }))
        }
        console.log(event)
        return await repository.save(event)
    }catch(error:any){

        for(const value of event.backgrounds){
            await StorageImageRepository.remove(value.key)
        }

        for(const value of event.frames){
            await StorageImageRepository.remove(value.key)
        }

        for(const value of event.stickers){
            await StorageImageRepository.remove(value.key)

        }

        //console.log(error.message)


        throw new Error(error.message)

    }

    
    
       
}