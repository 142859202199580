<template>
     
     <div :visible="visible" class="absolute-position color-background-modal w-screen h-screen flex justify-center align-center">
         <Modal :load="load" @confirmation="confirm" @cancel="cancel" />
     </div>

</template>
<script>
import Modal from "@/app/components/organisms/Modal.vue"
export default {
    props:{
        load:{
            type:Boolean,
            default:false
        }
    },
    components:{
        Modal
    },
    data(){
        return{
            visible:true
        }
    },
    methods:{
        confirm(value){
           if(value)  this.$emit("confirm",true)
        },
        cancel(value){

            if(value) this.$emit("cancel",true)

        }

    }
}

</script>

<style >
.absolute-position{
    position: fixed;
    top:0;
    left: 0;
    z-index: 1;

}
.color-background-modal{
    transition: all ease 0.5s;
    background-color: rgba(0, 0, 0, 0.4);
   

}
</style>