/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      organization
      time_zone
      active_from {
        date
        time
      }
      active_until {
        date
        time
      }
      webhook_url
      skip_disclaimer
      social_networks
      default_caption
      final_message
      backgrounds {
        bucket
        region
        key
      }
      frames {
        bucket
        region
        key
      }
      stickers {
        bucket
        region
        key
      }
      deleted
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organization
        time_zone
        active_from {
          date
          time
        }
        active_until {
          date
          time
        }
        webhook_url
        skip_disclaimer
        social_networks
        default_caption
        final_message
        backgrounds {
          bucket
          region
          key
        }
        frames {
          bucket
          region
          key
        }
        stickers {
          bucket
          region
          key
        }
        deleted
        createdOn
        updatedOn
        owner
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const getPhotoSubmissions = /* GraphQL */ `
  query GetPhotoSubmissions($id: ID!) {
    getPhotoSubmissions(id: $id) {
      id
      filename
      social_networks
      createdAt
      photo {
        bucket
        region
        key
      }
      eventID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listPhotoSubmissionss = /* GraphQL */ `
  query ListPhotoSubmissionss(
    $filter: ModelPhotoSubmissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotoSubmissionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filename
        social_networks
        createdAt
        photo {
          bucket
          region
          key
        }
        eventID
        createdOn
        updatedOn
        owner
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const photoSubmissionsByEvent = /* GraphQL */ `
  query PhotoSubmissionsByEvent(
    $eventID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPhotoSubmissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photoSubmissionsByEvent(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filename
        social_networks
        createdAt
        photo {
          bucket
          region
          key
        }
        eventID
        createdOn
        updatedOn
        owner
      }
      nextToken
      scannedCount
      count
    }
  }
`;
