<template>

<div style="border:1px solid black;padding-left:30px; padding-top:15px;padding-bottom:15px">
    <!-- <v-row>
        <v-col cols="auto">
            <span style="font-weight:bold;font-size:16px;">Shared On:</span> 
        </v-col>
        <v-col cols="auto">
            <span style="font-size:16px;"><span style="font-weight:bold">Facebook</span>: {{ metadata.countFacebook.data.photoSubmissionsByEvent}}</span> 
        </v-col>
        <v-col cols="auto">
            <span style="font-size:16px;"><span style="font-weight:bold">Twitter</span>: {{ metadata.countTwitter.data.photoSubmissionsByEvent}}</span> 
        </v-col>
         <v-col cols="auto">
            <span style="font-size:16px;"><span style="font-weight:bold">Linkedin</span>: {{ metadata.countLinkedIn.data.photoSubmissionsByEvent}}</span> 
        </v-col>

    </v-row> -->
</div>
    
</template>


<script>
export default {
    props:["metadata"],
}
</script>