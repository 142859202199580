<template>
<div class="fondo_client" style="height:100%;">

        <div :class="[height_screem]">
        <v-row justify="center" align="center" style="height:100%">
           
          
            <v-col v-show="show_alert" cols="auto">
                  <v-alert
                    dense
                    outlined
                    type="error"
                    >
                    <strong>not available</strong> 
                    </v-alert>
            </v-col>

        </v-row>

        </div>
        

</div>

    
</template>
<script>
//import GetUrlImage from "../../../context/admin/core/interactors/GetUrlImage"

export default {
    data(){
        return {
            active_shadow:true,
            active_shadow_lg:false,
            start_snap:false,
            height_screem:"h-3/5",
            skeleton:true,
            show_alert:true,
            circle_show:false,
            backgrounds_images:[],
            frames_images:[],
            stickers_images:[],
            item:{},
             URL_CLOUDFRONT:process.env.VUE_APP_URL_CLOUDFRONT,
             message_error:""
        }
    },
 
}
</script>
<style >
 .fondo_client{
     /* background-color:#EDEBEB; */
     background-color:  transparent;
     
 }

 
</style>