import Vue from 'vue'
import Vuex from 'vuex'
import CreateEvent from "../../context/admin/core/interactors/CreateEvent"

Vue.use(Vuex)

const field={
  value:"",
  error:{
    error:false,
    message:""
  }
}


export default new Vuex.Store({
  state: {
    form:{
      name:field,
      organization:field,
      active_from_date:field,
      active_from_time:field,
      active_until_date:field,
      active_until_time:field,
      time_zone:field,
      webhookUrl:field,
      social_networks:[],
      default_caption:field,
      backgrounds:[],
      frames:[],
      stickers:[]
    },
    events:[]
  },
  mutations: {
  
   
  },
  actions: {

  },
  modules: {
  }
})
