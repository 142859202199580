<template>
    <div >
             <div class="title_image">{{title}}</div>
            <div  style="height:120px;background-color:rgba(0,0,0,0.2);overflow:hidden" @click="modal_image=true" class="flex align-center rounded-sm">
             
                <v-img :eager="true" ref="preview" width="100%" :src="img"></v-img> 

            </div>
                <div style="margin-top:-45px;">
                    <slot></slot>
             
                </div>
                <v-dialog
                v-model="modal_image"
                width="700"
                >
               
                           <v-img :src="img" width="700"/>
                
                 
                </v-dialog>
                 
    </div>
</template>
<script>
export default {
    props:["title","img","indice","selected","heightImg"],
    data(){
        return{
            ex4:[],
            modal_image:false
        }
    }
}
</script>
<style scoped>
.title_image{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size:14px;
    line-height: 30px;
    color:black;
     white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
}
</style>
