<template>
    <div class="font-input-label">
                {{text}}
            </div>
</template>

<script>
export default {
    name:"Label",
    props:["text"]
}
</script>

<style scoped>
 .font-input-label{
     font-weight: bold;
     font-size: 16px;
     color: #2c3e50;
     margin-bottom: 8px;
 }

</style>