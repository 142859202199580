<template>
<div>
    <TopBar1/>
    <Content>
    <Title text="Edit photo booth"/>
    <FormEvent :edit="edit" :item="item" v-if="visible" />
    <PhotoSubmissions v-if="metadata_photosubmission_visible" :metadata="metadata_photosubmission"/>
    </Content>
    <Footer/>
</div>

</template>

<script>
import Title from "@/app/components/atoms/Title.vue"
import TopBar1 from "@/app/components/templates/TopBar1.vue"
import FormEvent from "@/app/components/organisms/FormEvent.vue"
import Content from "@/app/components/atoms/Content.vue"
import SearchEvent from "../../../context/admin/core/interactors/SearchEvent"
// import GetMetaDataSubmission from "../../../context/client/core/interactors/GetMetaDataPhotoSubmission"
import PhotoSubmissions from "../organisms/PhotoSubmissions.vue"
import Footer from '@/app/components/templates/Footer.vue';

export default {
    name:"EditEvent",
    components:{
        TopBar1,
        Title,
        FormEvent,
        Content,
        PhotoSubmissions,
        Footer
    },
    data(){
        return{
            other:{name:"",organization:""},
            item:{},
            edit:false,
            visible:true,
            metadata_photosubmission:{},
            metadata_photosubmission_visible:false
        }
    },
   async mounted(){
        
        try{
            const result = await SearchEvent(this.$route.params.id)

            this.metadata_photosubmission_visible=true
            // this.metadata_photosubmission=metadata_photosubmission
            this.item=result.data.getEvent
             
           this.edit=true

           this.visible = false;

        this.$nextTick(() => {
          // Add the component back in
          this.visible = true;
        });
             
        }catch(error){
            console.log(error.message)
        }


      
       
      
    }
}
</script>