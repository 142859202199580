<template>
<div>
<TopBar1 />
<Content>
  <Title text="Photo booths you manage"/>

      <v-row justify="space-between">
          <v-col cols="4">
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  dense
                  v-model="search"
                ></v-text-field>
          </v-col>
          <v-col cols="auto">
              <v-btn
              style="line-height:40px"
              dark
              color="blue-grey" 
              @click="go_create_event">
              Create a photo booth
              </v-btn>
          </v-col>
      </v-row>
  <TableEvents :search="search"/>
</Content>
<Footer />
</div>
    
</template>

<script>
import TopBar1 from "@/app/components/templates/TopBar1.vue"
import Footer from "@/app/components/templates/Footer.vue"
import TableEvents from "@/app/components/organisms/TableEvents.vue"
import Title from "@/app/components/atoms/Title.vue"
import Content from "@/app/components/atoms/Content"

/*import { API } from 'aws-amplify';
import * as queries from "../../../graphql/queries"
import * as mutations from '../../../graphql/mutations';*/
//import {Storage } from 'aws-amplify';
export default {
    name:"Events",
    data(){
      return {
        search:""
      }
    },
    async mounted(){

    // const allTodos = await API.graphql({ query: queries.listEvents });
    //console.log(allTodos); // result: { "data": { "listTodos": { "items": [/* ..... */] } } }
    /*console.log( allTodos.data.listEvents)
    const todoDetails = {
  id: allTodos.data.listEvents.items[0].id
};

await API.graphql({ query: mutations.deleteEvent, variables: {input: todoDetails}});*/

     /* Storage.list('')
    .then(result => {
      console.log(result)
    })
    .catch(err => console.log(err));*/
    },
    components:{
        TopBar1,
        TableEvents,
        Title,
        Footer,
        Content
    },
    methods:{
      go_create_event(){
        this.$router.push('create')
      }
    }

}
</script>
