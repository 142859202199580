<template>
  <footer class="footer-principal">
    <div class="footer-section">
      <Content>
        <div class="first-row">
          <div>
            <a href="">
              <img src="/logo-pink.png" height="50px" width="243px">
            </a>
          </div>
          <div>
            <p>
              <b>Simply Photo Booths</b> is made by Solve IT Simply.<br>
              Our mission is to make things that are simple.<br>
              Because simple makes people happy.<br>
              Learn more at <a href="https://www.solveitsimply.com/" class="p-link"><b>solveitsimply.com</b></a>.
            </p>
          </div>
			</div>
      </Content>
		</div>
  </footer>
</template>

<script>
import Content from "@/app/components/atoms/Content"
import { Auth } from 'aws-amplify';

export default {
  name: 'Footer',
  components:{Content},
    methods:{
        go_home(){
            this.$router.push('/')
        },
        async signOut() {
            try {
                await Auth.signOut();
                window.location.href="/"
            } catch (error) {
                console.log('error signing out: ', error);
            }
        }
    }
}
</script>

<style scoped>
.footer-principal {
  margin-top: 100px;
}
.footer-section {
	background-color: black;
	color: white;
  position: relative;
  bottom: 0;
  width: 100%;
}

.first-row {
	display: flex;
	flex-direction: column;
	font-size: 16px;
  padding: 50px 0px;
}

.p-link {
	color: rgb(239, 70, 102);
}
</style>