<template>
    <div>
        <v-btn  color="blue-grey" dark @click="open_file">Add {{addText}} <v-icon
            right
            dark
        >
        mdi-plus-circle
        </v-icon></v-btn>
        <input type="file" multiple ref="file" @change="preview_img" hidden>
    </div>
</template>

<script>
//import Swal from 'sweetalert2'
export default {
    props:["addText"],
    data(){
        return {
            all_files:[]
        }
    },

    methods:{
         open_file(){
            this.$refs.file.click()
        },
         preview_img(evt){
             this.all_files=[]
               for (let i=0;i<evt.target.files.length ;i++){

                 let reader= new FileReader()
                
                reader.onload= (e)=>{

                    try{

                 let splits_names_of_file= evt.target.files[i].name.split(".")
                   
                let file= evt.target.files[i]
                let title= splits_names_of_file[0]
                let url_img=e.target.result

                if(splits_names_of_file[splits_names_of_file.length-1]!=="svg" && 
                splits_names_of_file[splits_names_of_file.length-1]!=="jpg" && 
                splits_names_of_file[splits_names_of_file.length-1]!=="png" &&
                splits_names_of_file[splits_names_of_file.length-1]!=="jpeg"){

                    this.all_files=[]
                  //  this.$emit('onChangeFile',this.all_files)
                             
                             throw new Error("only formats: svg , jpg , png and jpeg")
                }

                this.all_files.push({file:file,title:title,url_img:url_img})

              

                if(this.all_files.length===evt.target.files.length){

                    let aux_files= this.all_files
                     this.all_files=[]
                     this.$refs.file.value=""
                    
                    this.$emit('onChangeFile',aux_files)
                }

                 }catch(error){

             /*  Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
                })*/
                alert(error.message)
           }

            
            }
            reader.readAsDataURL(evt.target.files[i])

            } 
        }
    }
    
}
</script>
