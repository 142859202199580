<template>
<div class="color-fondo">
<v-row>
    <v-col cols="6" class="alinear">{{text_images_selecteds}}</v-col>
</v-row>

</div>
</template>
<script>
export default {
    props:["text_images_selecteds"]
}
</script>

<style scoped>


.color-fondo{
    background-color: #EDEBEB;
}

.alinear{
    text-align: center;
    font-size: 15px;
}
</style>