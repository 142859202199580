<template>
<div>  
   <v-row>
    <v-col>
      <v-alert
      color="#ff5252"
      outlined 
      dense
      v-show="missing_fields_message"
      type="error">
      Please, complete the missing fields.
    </v-alert>
    </v-col>
   </v-row>
    <v-row justify="space-between">
        <v-col cols="6">
            <InputLabelBold 
            text="Photo booth name" 
            :value.sync="form.name.value"
            :error_message.sync="form.name.message" 
            placeholder="name"
             />
        </v-col>
        <v-col cols="6">
            <InputLabelBold 
            text="Organization name" 
            :value.sync="form.organization.value" 
            :error_message.sync="form.organization.message" 
            placeholder="organization" />
        </v-col>
    </v-row>

    <v-row  style="margin-top:-30px">
        <v-col cols="6"><div class="font-label-bold">Active From</div> </v-col>
        <v-col cols="6"><div class="font-label-bold">Active Until</div></v-col>
    </v-row>
   
    <v-row style="margin-top:-20px">
        <v-col cols="3">
            <InputCalendar 
            :error_message.sync="form.active_from_date.message"
            :obligatory="true"
            :value.sync="form.active_from_date.value"
            />
        </v-col>
         <v-col cols="3">
            <InputTime
            :obligatory="true"
            :error_message.sync="form.active_from_time.message" 
            :value.sync="form.active_from_time.value"
            />
        </v-col>
         <v-col cols="3">
             <InputCalendar
             :obligatory="false"
             :error_message.sync="form.active_until_date.message" 
             :value.sync="form_active_until_date"
             />
        </v-col>
         <v-col cols="3">
             <InputTime
             :obligatory="form_active_until_time_obligatory"
             :error_message.sync="form.active_until_time.message" 
             :value.sync="form.active_until_time.value"
             /> 
        </v-col>
    </v-row>
    <v-row style="margin-top:-15px">
        <v-col cols="3">
           <InputSelectBold 
           text="Time Zone"
           :error_message="form.time_zone.message" 
           :items="items" 
           :value.sync="form.time_zone.value"
            />

        </v-col>
    </v-row>
    <v-row style="margin-top:-15px">
        <v-col cols="3"><div class="font-label-bold" style="line-height:40px;">Post Photos to Webhook URL</div></v-col>
        <v-col cols="9"><div><v-text-field 
                   v-model="weeb_hook_value"
                    outlined
                    dense
                    :error-messages="form.webhookUrl.error"
                    @focusout="focusout_weebhook"
                    /></div> </v-col>
    </v-row>
    <v-row style="margin-top:-15px">
        <v-col cols="3"><div class="font-label-bold">Skip Disclaimer Step</div> </v-col>
        <v-col cols="4">
            <switches v-model="swt_disclaimer" @change="change()" :color="[color_swt_disclaimer]"></switches>
        </v-col>
    </v-row>
     <v-row>
        <v-col cols="2">
            <div class="font-label-bold">Final Message</div> 
        </v-col>
        <v-col cols="8">
            <v-textarea
          outlined
          dense
          v-model="form.final_message.value"
          name="input-7-4"
        ></v-textarea>
        </v-col>
    </v-row>
    <v-row style="margin-top:-15px">
        <v-col cols="3"><div class="font-label-bold">Enable Share</div> </v-col>
        <v-col cols="4">
            <switches v-model="switch1"  @change="change()" :color="[color_swt_networks]"></switches></v-col>
    </v-row>

    <v-row justify="center" v-show="switch1" style="margin-top:-15px">
        <v-col v-for="item in social_networks" :key="item.value" cols="2">
            <v-checkbox
              v-model="form.social_networks"
              :label="item.label"
              color="#EEA22F"
              :value="item.value"
              hide-details
            ></v-checkbox>
        </v-col>
    </v-row>
    <v-row v-show="switch1">
        <v-col cols="2">
            <div class="font-label-bold">Default Caption</div> 
        </v-col>
        <v-col cols="8">
            <v-textarea
          outlined
          dense
          v-model="form.default_caption.value"
          name="input-7-4"
        ></v-textarea>
        </v-col>
    </v-row>
    <v-row v-if="edit" justify="end">
        <v-col cols="auto">
             <v-btn
              width="200"
              dark
              color="blue-grey" 
              @click="editar_event(1)"
              >
              Save
              </v-btn>
        </v-col>
        <v-col cols="auto">
             <v-btn
              width="200"
              dark
              color="blue-grey" 
              @click="editar_event(2)"
              >
              Save & Close
              </v-btn>
        </v-col>

    </v-row>
    <Gallery title="Backgrounds" 
    :error_flag.sync="form.backgrounds_error.error" 
    :error_message.sync="form.backgrounds_error.message" 
    :images.sync="form.backgrounds" 
    :images_edit="images_edit_background"
    :edit="edit"
    :item="item"
    v-if="visible_background"
    />
    <Gallery title="Frames"
    :error_flag.sync="form.frames_error.error" 
    :error_message.sync="form.frames_error.message"  
    :images.sync="form.frames"
    :images_edit.sync="images_edit_frame"
     :edit="edit"
       :item="item"
     v-if="visible_frame"
    />
    <Gallery title="Stickers"
    :error_flag.sync="form.stickers_error.error" 
    :error_message.sync ="form.stickers_error.message"  
    :images.sync="form.stickers"
     :images_edit.sync="images_edit_sticker"
     :edit="edit"
       :item="item"
     v-if="visible_sticker"
    />
     <v-row justify="center" v-if="!edit" style="margin-bottom:40px;margin-top:40px">
            <v-col cols="4"><v-btn :loading="create_lodading" color="#FFAD32" @click="create" style="width:100%;"><span style="color:white">Create</span></v-btn></v-col>
        </v-row>

      <Loader v-show="loader_show" /> 
    
</div>
</template>

<script>

//import Label from "@/components/atomos/Label.vue"
import Switches from 'vue-switches';
import InputLabelBold from "@/app/components/molecules/InputLabelBold.vue"
import InputCalendar from "@/app/components/molecules/InputCalendar.vue"
import InputTime from "@/app/components/molecules/InputTime.vue"
import InputSelectBold from "@/app/components/molecules/InputSelectBold.vue"
import Gallery  from "@/app/components/organisms/Gallery.vue"
import CreateEvent from "../../../context/admin/core/interactors/CreateEvent"
//import GetListImages from "../../../context/admin/core/interactors/GetListImages"
//import GetUrlImage from "../../../context/admin/core/interactors/GetUrlImage"
import EditEvent from "../../../context/admin/core/interactors/EditEvent"
import Loader from "@/app/components/atoms/Loader.vue"
export default {
    props:["edit","item"],
 name:"FormEvent",
 components:{
    //Label,
     Switches,
     InputLabelBold,
     InputCalendar,
     InputTime,
     InputSelectBold,
     Gallery,
     Loader
 },
 mounted(){
         this.switch_enable_social_networks()
         this.load_values_edit()
         if(!this.edit){
              const d = new Date()
             this.form.active_from_date.value = d.toISOString().split("T")[0]
              this.form.active_from_time.value = d.getHours()+":"+d.getMinutes()
         }
       
 },
 data(){
     return {
         visible_background:true,
         visible_frame:true,
         visible_sticker:true,
         images_edit_background:[],
         images_edit_frame:[],
         images_edit_sticker:[],
         create_lodading:false,
         missing_fields_message:false,
         form_active_until_date:"",
         form_active_until_time_obligatory:false,
         form:{
             name:{ value:"", error:false, message:""},
             organization:{ value:"", error:false, message:""},
             active_from_date:{value:"", error:false, message:""},
             active_from_time:{value:"",error:false,message:""},
             active_until_date:{value:"",error:false,message:""},
             active_until_time:{value:"",error:false,message:""},
             time_zone:{value:"PT",error:false,message:""},
             webhookUrl:{value:"",error:"",message:""},
             social_networks:['twitter','facebook','instagram','linkedin'],
             default_caption:{value:"",error:false,message:""},
             final_message:{value:"",error:false,message:""},
             backgrounds:[],
             backgrounds_error:{error:false,message:""},
             frames:[],
             frames_error:{error:false,message:""},
             stickers:[],
             stickers_error:{error:false,message:""},
             disclaimer: {value:false,error:false,message:""},
         },
          name:"",
      ex4:[],
      items: [
          {text:"PT - Pacific Time",value:"PT"},
          {text:"MT - Mountain Time",value:"MT"},
          {text:"CT - Central Time",value:"CT"},
          {text:"ET - Eastern Time",value:"ET"}
          ],
      swt_disclaimer: false,
      switch1: true,
      menu: false,
      modal: false,
      menu2: false,
      social_networks:[
          {label:"Twitter",value:"twitter"},
          {label:"Facebook",value:"facebook"},
          {label:"LinkedIn",value:"linkedin"}],
      loader_show:false,
      URL_CLOUDFRONT:process.env.VUE_APP_URL_CLOUDFRONT,
      color_swt_disclaimer: 'red',
      color_swt_networks: 'green'
     }
 },
 methods:{
     focusout_weebhook(){
          var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); 

         if(this.weeb_hook_value.length>0){
             if(!pattern.test(this.weeb_hook_value)){
               this.form.webhookUrl.error="the WebHook should be a valid URL."
             }else{
                 this.form.webhookUrl.error=""
             }
             
         }else{
             this.form.webhookUrl.error=""
         }

     },
     async editar_event(option){
         const objectToUpdate={
            name:this.form.name.value,
            organization:this.form.organization.value,
            active_from:{
                date:this.form.active_from_date.value,
                time:this.form.active_from_time.value
            },
            active_until:{
                date:this.form.active_until_date.value,
                time:this.form.active_until_time.value
            },
            default_caption:this.form.default_caption.value,
            final_message:this.form.final_message.value,
            time_zone:this.form.time_zone.value,
            webhookUrl:this.form.webhookUrl.value,
            social_networks:this.form.social_networks,
            disclaimer:this.form.disclaimer.value
         }
          try{
                console.log(objectToUpdate);
                this.loader_show=true
                await EditEvent(this.item.id,objectToUpdate)
                this.loader_show=false
                if(option == 2) {
                    this.$router.back()
                }
          }catch(error){
                 window.scrollTo( 0, 0 );
             this.missing_fields_message=true
                if(typeof error.message === "undefined"){
                    
               
                 if(error.includes("name")){
                     this.form.name.message="the field event name is obligatory"
                 } 
                 if(error.includes("organization")){
                     this.form.organization.message="the field organization name is obligatory"
                 }

                 if(error.includes("active_from_date")){
                     this.form.active_from_date.message="the field date is obligatory"
                 }

                  if(error.includes("active_from_time")){
                     this.form.active_from_time.message="the field time is obligatory"
                 }

                   if(error.includes("active_until_date")){
                     this.form.active_until_date.message="the field date is obligatory"
                 }

                  if(error.includes("active_until_time")){
                     this.form.active_until_time.message="the field time is obligatory"
                 }

                 if(error.includes("time_zone")){
                     this.form.time_zone.message="the field time zone is obligatory"
                 }

                 if(error.includes("backgrounds")){
                     this.form.backgrounds_error.error=true,
                     this.form.backgrounds_error.message="at least one image to  create an event"
                 } 

                 if(error.includes("webhook")){
                     this.form.webhookUrl.error="the WebHook should be a valid URL."
                 }

                 if(error.includes("frames")){
                      this.form.frames_error.error=true,
                     this.form.frames_error.message="at least one image to  create an event"
                 }

                 if(error.includes("stickers")){
                          this.form.stickers_error.error=true,
                     this.form.stickers_error.message="at least one image to  create an event"

                 }
            }else{
                alert(error.message)
            }

          }

          this.loader_show=false

     },
     async load_values_edit(){
         if(this.edit){
            
             this.form.name.value=this.item.name
             this.form.organization.value= this.item.organization
             this.form.active_from_date.value=this.item.active_from.date
             this.form.active_from_time.value= this.item.active_from.time
             this.form.active_until_date.value=this.item.active_until.date
             this.form_active_until_date =  this.item.active_until.date
             this.form.active_until_time.value= this.item.active_until.time
             this.form.time_zone.value=this.item.time_zone
             this.form.webhookUrl.value=this.item.webhook_url
             this.form.default_caption.value=this.item.default_caption
             this.form.final_message.value= this.item.final_message
             this.form.social_networks=this.item.social_networks
             this.form.disclaimer.value=this.item.skip_disclaimer
          
             if(this.form.social_networks.length===0){
                 this.ex4=[]
             }

             if(this.form.disclaimer.value) {
                this.swt_disclaimer = true
             }
            
            let background_urls = []

             for(const value of this.item.backgrounds){
                
                  const url =  this.URL_CLOUDFRONT+ value.key
                  const title = value.key.split("/")[2]

                  background_urls.push({url,title})
            }
            this.images_edit_background=background_urls    
              this.visible_background = false;

        this.$nextTick(() => {
          // Add the component back in
          this.visible_background = true;
        });

       //  const result2= await GetListImages(this.$route.params.id+"/frames/")
           
            let frame_urls = []

             for(const value of this.item.frames){
                  const url = this.URL_CLOUDFRONT+value.key
                  const title = value.key.split("/")[2]

                  frame_urls.push({url,title})
            }

            this.images_edit_frame=frame_urls    
              this.visible_frame = false;

        this.$nextTick(() => {
          // Add the component back in
          this.visible_frame = true;
        });


       // const result3= await GetListImages(this.$route.params.id+"/stickers/")
            
            let sticker_urls = []

             for(const value of this.item.stickers){
                  const url = this.URL_CLOUDFRONT+ value.key
                  const title = value.key.split("/")[2]
                  sticker_urls.push({url,title})
            }

            this.images_edit_sticker=sticker_urls    
              this.visible_sticker = false;

        this.$nextTick(() => {
          // Add the component back in
          this.visible_sticker = true;
        });
          
          
          
            
         }
     },
    switch_enable_social_networks(){
         if(this.switch1){
             this.social_networks.forEach(el=>{this.ex4.push(el.value)})
         }else{
            this.ex4=[]
         }
    },
    change(){
    },
    async create(){
        this.loader_show=true
        this.create_lodading=true
        const object_prepared_to_send={
            name:this.form.name.value,
            organization:this.form.organization.value,
            active_from:{
                date:this.form.active_from_date.value,
                time:this.form.active_from_time.value
            },
            active_until:{
                date:this.form.active_until_date.value,
                time:this.form.active_until_time.value
            },
            default_caption:this.form.default_caption.value,
            final_message:this.form.final_message.value,
            time_zone:this.form.time_zone.value,
            webhookUrl:this.form.webhookUrl.value,
            social_networks:this.form.social_networks,
            backgrounds:this.form.backgrounds,
            frames:this.form.frames,
            stickers:this.form.stickers,
            disclaimer:this.form.disclaimer.value
        }

        try{
          await  CreateEvent(object_prepared_to_send)
        
          this.loader_show=false
          this.create_lodading=false
          this.$router.push("/")
        }catch(error){
            console.log(error);

            this.create_lodading=false
             this.loader_show=false
             window.scrollTo( 0, 0 );
             this.missing_fields_message=true
            if(typeof error.message === "undefined"){
             
                 if(error.includes("name")){
                     this.form.name.message="the field event name is obligatory"
                 } 
                 if(error.includes("organization")){
                     this.form.organization.message="the field organization name is obligatory"
                 }

                 if(error.includes("active_from_date")){
                     this.form.active_from_date.message="the field date is obligatory"
                 }

                  if(error.includes("active_from_time")){
                     this.form.active_from_time.message="the field time is obligatory"
                 }

                   if(error.includes("active_until_date")){
                     this.form.active_until_date.message="the field date is obligatory"
                 }

                  if(error.includes("active_until_time")){
                     this.form.active_until_time.message="the field time is obligatory"
                 }

                 if(error.includes("time_zone")){
                     this.form.time_zone.message="the field time zone is obligatory"
                 }

                 if(error.includes("backgrounds")){
                     this.form.backgrounds_error.error=true,
                     this.form.backgrounds_error.message="at least one image to  create an event"
                 } 

                 if(error.includes("webhook")){
                     this.form.webhookUrl.error="the WebHook should be a valid URL."
                 }

                 if(error.includes("frames")){
                      this.form.frames_error.error=true,
                     this.form.frames_error.message="at least one image to  create an event"
                 }

                 if(error.includes("stickers")){
                          this.form.stickers_error.error=true,
                     this.form.stickers_error.message="at least one image to  create an event"

                 }
            }else {
                console.log(error.message)
            }
            
        }
    }
 },
 computed:{
     weeb_hook_value:{
         get(){
              return this.form.webhookUrl.value
         },
         set(value){
             this.form.webhookUrl.value= value
         }
     }
 },
 watch:{
     form_active_until_date(value){
         if(typeof value === "undefined"){
              this.form.active_until_date.value=""
        this.form_active_until_time_obligatory=false
        return
         }
         if(value.length>0){
             this.form_active_until_time_obligatory=true
             this.form.active_until_date.value=value
         }else{
              this.form.active_until_date.value=""
             this.form_active_until_time_obligatory=false
         }

     },
     weeb_hook_value(value){
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); 

         if(value.length>0){
             if(!pattern.test(value)){
               this.form.webhookUrl.error="the WebHook should be a valid URL."
             }else{
                 this.form.webhookUrl.error=""
             }
             
         }else{
             this.form.webhookUrl.error=""
         }

     },
     switch1(newvalue){
                if(newvalue){
                    this.color_swt_networks = 'green';
                    if(this.form.social_networks.length===0){
                        this.social_networks.forEach(el=>{
                            this.form.social_networks.push(el.value)
                        })
                }
                }else{
                    this.color_swt_networks = 'red';
                    this.form.social_networks=[]
                }
     },
     ex4(newvalue){

         if(newvalue.length>0){
             this.switch1=true
         }else{
             this.switch1=false
         }

     },
     swt_disclaimer(newvalue){
        if (newvalue) {
            this.color_swt_disclaimer = 'green';
            this.swt_disclaimer=true;
            this.form.disclaimer.value = true;
        } else {
            this.color_swt_disclaimer = 'red';
            this.swt_disclaimer=false;
            this.form.disclaimer.value = false;
        }
     }
 }
}
</script>
<style >
html {
    
   scroll-behavior: smooth;
}
.font-label{
    font-size: 16px;
    margin-bottom: 8px;

}

.font-label-bold{
     font-weight: bold;
     font-size: 16px;
     color: #2c3e50;
}

</style>
