import { PhotoSubmissions } from "../../core/entities/PhotoSubmission";
import { PhotoSubmissionRepository } from "../../core/repository/PhotoSubmissionRepository";
import { API, graphqlOperation } from 'aws-amplify';
import {Storage } from 'aws-amplify';
import * as queries from "../../../../graphql/queries"
import { searchPhotoSubmissionssTotal } from "../../../queries/queries"
import {createPhotoSubmissions,deletePhotoSubmissions,updatePhotoSubmissions}  from "../../../../graphql/mutations"
import { photoSubmissionsByEvent } from "../../../../graphql/queries";
export  class PhotoSubmissionSourceGraphql implements PhotoSubmissionRepository{
    async create(Photo: PhotoSubmissions): Promise<any> {
        const date = new Date()
        
        
        const result=  await  API.graphql({...graphqlOperation(createPhotoSubmissions,{input:{
            id:Photo.id,
            filename: Photo.filename,
            social_networks:[],
            photo:Photo.photo,
            createdAt:date.toISOString(),
            eventID:Photo.eventID
        }}),authMode:"API_KEY"})
        
        await Storage.put(Photo.photo.key,Photo.file)
        return result
    }
    async searchBefore(id: string,next_token:string): Promise<any> {
        
        const value:any=await API.graphql({ query: queries.photoSubmissionsByEvent, variables:{
            
                eventID: id,
            
            sort:{field:"createdAt" , direction:"desc"},
            nextToken:next_token===""?null:next_token
            
        },authMode:"API_KEY"});        
        
        const myvalue:any = await API.graphql({query: photoSubmissionsByEvent,variables:{
            
            eventID: id,
            
        },authMode:"API_KEY"})
        
        /*    for(const item of value.data.listPhotoSubmissionss.items){
            await Storage.remove(item.photo.key)
            await API.graphql({...graphqlOperation(deletePhotoSubmissions,{input:{id:item.id}}),authMode:"API_KEY"})
        }  */
           
        
        return {...value.data.photoSubmissionsByEvent}
    }
    async search(id: string,next_token:string): Promise<any> {
        
        const value:any=await API.graphql({ query: queries.photoSubmissionsByEvent, variables:{
            
                eventID: id,
            
            sort:{field:"createdAt" , direction:"desc"},
            limit:12 ,
            nextToken:next_token===""?null:next_token
            
        },authMode:"API_KEY"});        
        
        const myvalue:any = await API.graphql({query: photoSubmissionsByEvent,variables:{
            
            eventID: id,
            
        },authMode:"API_KEY"})
        
        /*    for(const item of value.data.listPhotoSubmissionss.items){
            await Storage.remove(item.photo.key)
            await API.graphql({...graphqlOperation(deletePhotoSubmissions,{input:{id:item.id}}),authMode:"API_KEY"})
        }  */
           
        
        return {...value.data.photoSubmissionsByEvent}
    }
    
    async  registerSocialNetwork(id:string,socialnetwork: string): Promise<any> {
        
        const result:any = await API.graphql({ query: queries.getPhotoSubmissions, variables: { id:id},authMode:"API_KEY"})
        
        if(!result.data.getPhotoSubmissions.social_networks.includes(socialnetwork)){
            
            const social_shared= result.data.getPhotoSubmissions.social_networks
            social_shared.push(socialnetwork)
            
            return  await  API.graphql({...graphqlOperation(updatePhotoSubmissions,{input:{
                id:id,
                social_networks:social_shared
            }}),authMode:"API_KEY"})
        }
        
        return "social network was already saved"
    }
    async getMetadata(id:string): Promise<any> {

        const countFacebook =  await API.graphql({ query: photoSubmissionsByEvent, variables:{
            
            filter:{
                social_networks:{
                    eq:  "Facebook" 
                },
                eventID:{
                    eq:id
                }
            }
            
        },authMode:"API_KEY"});

        const countTwitter =  await API.graphql({ query: photoSubmissionsByEvent, variables:{
            
            filter:{
                social_networks:{
                    eq:  "Twitter" 
                },
                eventID:{
                    eq:id
                }
            }
            
        },authMode:"API_KEY"});

        const countLinkedIn =  await API.graphql({ query: photoSubmissionsByEvent, variables:{
            
            filter:{
                social_networks:{
                    eq:  "LinkedIn" 
                },
                eventID:{
                    eq:id
                }
            }
            
        },authMode:"API_KEY"});
        
        return {countFacebook,countTwitter,countLinkedIn}
    }


    async delete(idphoto:string,key:string):Promise<any>{

        
        
        await Storage.remove(key)
        
      return  await API.graphql({...graphqlOperation(deletePhotoSubmissions,{
            input:{
              id:idphoto
            }
        }),authMode:"API_KEY"})


    }
}