<script>
import * as queries from "../../../graphql/queries";
import API from '@aws-amplify/api';

export default {
    name:"SharedPhoto",
    async mounted(){
        try {
            const result = await API.graphql({query: queries.getPhotoSubmissions, variables: {
                id: this.$route.params.idPhoto
            }, authMode: "API_KEY"});
            const shortUrl = process.env.VUE_APP_URL_CLOUDFRONT+result.data.getPhotoSubmissions.photo.key;
            window.location.replace(shortUrl);
        } catch (error) {
            console.log(error.message)
        }
    }
}
</script>
