<template>
<div>
    <div v-show="load" class="color-background w-96 px-4 py-2 rounded" >
      <v-row  justify="center">
               <v-col cols="auto">
                   <v-progress-circular
           indeterminate
         color="#EEA22F"
        ></v-progress-circular>
               </v-col>
        </v-row>
   </div>
    <div v-show="!load" class="color-background w-96 px-4 py-2 rounded" >
      
        <v-row  >

            <v-col cols="2" style="text-align:center">
         <v-icon color="#CB4335">
             mdi-alert-outline
         </v-icon>

            </v-col>
            <v-col cols="10">
                <div>

                Do you want delete this Images?
                </div>
                <div style="font-size:13px;color:gray">
                    This action cannot be undone.
                </div>
            </v-col>

        </v-row>
        <v-row  justify="end">
            <v-col cols="auto">
                <v-btn small dark color="#CB4335" @click="confirm" >Delete</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn small dark @click="cancel" >cancel</v-btn>
            </v-col>
        </v-row>
    </div>

</div>

</template>
<script>
export default {
    name:"Modal",
    props:{
        load:{
            type:Boolean,
            default:false
        }
    },
data(){
    return {

    }
},
methods:{
    confirm(){
        this.$emit("confirmation",true)
    },
    cancel(){
        this.$emit("cancel",true)
    }
}
}
</script>
<style >
.color-background{
    position: relative;
    z-index: 5;
    background-color:white;
    
}
</style>