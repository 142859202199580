import { EventRepository } from "../../repository/EventRepository";
import { EventImageRepository } from "../../repository/EventImageRepository";

export const DeleteEvent = (repository:EventRepository,StorageImageRepository:EventImageRepository) => async (item:any)=> {

     
    item.backgrounds.forEach((value:any)=>{
        
        StorageImageRepository.remove(value.key)
               
    })

    item.frames.forEach((value:any)=>{
        
        StorageImageRepository.remove(value.key)
               
    })

    item.stickers.forEach((value:any)=>{
        
        StorageImageRepository.remove(value.key)
               
    })


   return   await repository.delete(item.id)

}