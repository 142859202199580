<template>
<div>
  <div  v-if="authState !== 'signedin' && !user&& !flag_route" style="position:absolute;top:0;left:0;right:0">
 <TopBar :controls="false"/>
  </div>
 <v-app  v-if="flag_route">
   
      <router-view />
    </v-app>


<amplify-authenticator v-if="authState!=='signedin' && !flag_route">
 
          <amplify-sign-in slot="sign-in">
        
          <div slot="secondary-footer-content"></div>
        </amplify-sign-in>
   
  </amplify-authenticator>
  
   
   <v-app v-if="authState === 'signedin' && user && !flag_route">
    
    <router-view/>
    </v-app>
    <div  v-if="authState !== 'signedin' && !user &&!flag_route" style="position:absolute;bottom:0px;left:0;right:0;">
    <Footer/>
    </div>
</div>

    
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import TopBar from "./components/templates/TopBar1"
import Footer from "./components/templates/Footer2"

export default {
   components:{
     TopBar,
     Footer
   },
  data(){
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      route_client:"client",
      route_register:"register",
      flag_route:undefined
    //  flag_route:false
      }
},

mounted(){
  if(this.$route.name===this.route_client || this.$route.name===this.route_register){
this.flag_route = true
  }
    
 this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      localStorage.setItem("user",this.user.attributes.sub)
      
     /*  if(this.$route.name===this.route_client){
         this.flag_route === true
         console.log("entre")
       }else{
      this.flag_route === false
        }*/
    })
    
 
},
beforeDestroy() {
    this.unsubscribeAuth();
  }
}
</script>

<style >

#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #2c3e50;
}
.v-btn__content {
  text-transform: none !important;
}
   button.button{
  background-color:#D35400 !important;
}
</style>
