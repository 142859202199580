<template>
<div>
    <v-row>
        <v-col cols="12"><Title2 :title="title"/></v-col>
    </v-row>
    <v-row >
        <v-col cols="auto" > <LabelHover @select="select_clear_all" :text="text_select" /> </v-col>
        <v-col cols="auto"><v-btn  color="#FFAD32" @click="open_dialog_create" v-if="!edit" :disabled="disabled"><span style="color:white">Delete</span></v-btn></v-col>
         <v-col cols="auto"><v-btn  color="#FFAD32" @click="open_dialog"  v-if="edit" :disabled="disabled"> <span style="color:white">Delete</span> </v-btn></v-col>
        <v-col cols="auto"><v-btn   color="#6D8794" v-if="edit"  :disabled="disabled" @click="download_images"><span style="color:white" > Download</span></v-btn></v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
            <FileImageInput @onChangeFile="change_file" :addText="title" v-if="!edit" />
            <FileImageInput @onChangeFile="change_file_edit" :addText="title" v-if="edit" />
      </v-col>
      <v-col cols="3" v-if="edit">
          <Pagination :from="from" :to="to" :mount="mount" @next="next_slider" @back="back_slider"  />
      </v-col>
    </v-row>
    <Divisor/>
    <ImagesSelecteds v-if="edit" :text_images_selecteds="text_images_selecteds"/>
     <v-alert dense v-if="flag_error" text type="error">
      {{message_error}}
    </v-alert>
   <v-row style="margin-bottom:40px">


        <v-col v-if="flag" cols="12"><div style="text-align:center;line-height:100px;font-size:15px"> No {{title}} </div></v-col>

        <v-col cols="2" v-for="(item,index) in gallery_images" :key="index" v-show="item.show"> 
            <PaperImage :title="item.title" :img="item.img" :indice="index" :selected="ex4">
                <div class="personalize-checkbox">
                <v-checkbox
                style="float:right;"
                v-model="ex4"
                color="#EEA22F"
                :value="index"
                hide-details
                />
                </div>
            </PaperImage>
        </v-col>
  
    </v-row>
  <DialogAlert :load="loader" @confirm="confirm_delete" @cancel="close_modal" v-show="dialog"/>
  <DialogAlert :load="loader_create" @confirm="confirm_delete_create" @cancel="close_modal_create" v-show="dialog_create"/>
  <Loader v-if="add_gallery_loader" />
</div>
    
</template>
<script>

import PaperImage from "@/app/components/molecules/PaperImage.vue"
import Title2 from "@/app/components/atoms/Title2.vue"
import Divisor from "@/app/components/atoms/Divisor.vue"
import FileImageInput from "@/app/components/molecules/FileImageInput.vue"
import LabelHover from "@/app/components/atoms/LabelHover.vue"
import Pagination from "@/app/components/molecules/Pagination.vue"
import ImagesSelecteds from "@/app/components/molecules/ImagesSelecteds.vue"
//import Swal from 'sweetalert2'
import {downloadAndZip} from "@/app/helpers/donwloadZip"
import DeleteImage from "@/context/admin/core/interactors/DeleteImage"
import AddImage from "@/context/admin/core/interactors/AddImage"
import GetUrlImage from "../../../context/admin/core/interactors/GetUrlImage"
import DialogAlert from "../organisms/DialogAlert.vue"
import Loader from '../atoms/Loader.vue'


export default {
    name:"Gallery",
    props:["title","images","error_flag","error_message","edit","gallery","images_edit","item"],
    components:{
        PaperImage,
        Title2,
        Divisor,
        FileImageInput,
        LabelHover,
        Pagination,
        ImagesSelecteds,
        DialogAlert,
        Loader
    },
    data(){
        return {
            text_select:"Select all",
            disabled:true,
            ex4:[],
            flag:true,
            gallery_images:[],
            files_images:[],
            src:"",
            select_all_text:"Select all",
            clear_all_text:"Unselect all",
            dialog:false,
            dialog_create:false,
            loader:false,
            loader_create:false,
            add_gallery_loader:false,
            from:0,
            to:0,
            mount:0,
            //count slides
            sliders_cant:0,
            position_slide:0,
            URL_CLOUDFRONT:process.env.VUE_APP_URL_CLOUDFRONT,
            text_images_selecteds:undefined
        }
    },
    mounted(){
         this.text_images_selecteds= `0 ${this.title.toLowerCase()} have been selected from this page`
       
       // this.gallery_images = this.gallery
     //  console.log(this.item)
        this.load_images_edit()
         this.initialize_pagination()
         this.load_positions_slide()
    },
    methods:{
        confirm_delete_create(){
            this.loader_create=true
             this.gallery_images= this.gallery_images.filter((el,index)=>!this.ex4.includes(index))
                    this.files_images= this.files_images.filter((el,index)=>!this.ex4.includes(index))

                    this.ex4=[]
                    this.text_select=this.select_all_text
                 this.loader_create=false
                 this.dialog_create=false
                    if(this.gallery_images.length===0) this.flag=true
        },
        next_slider(){
            if(this.position_slide<this.sliders_cant-1){
               this.position_slide=this.position_slide+1
               this.load_positions_slide()
            }
        },
        back_slider(){
                if(this.position_slide>0){
               this.position_slide=this.position_slide-1
               this.load_positions_slide()
            }
            /*console.log(this.position_slide)*/
        },
        load_positions_slide(){
            if(this.edit){
                this.mount=this.gallery_images.length
                const cant= this.gallery_images.length
                this.sliders_cant=Math.ceil(cant/12)
                   let aux_start=this.position_slide*12
                   let aux_end =  this.gallery_images.length- 12*this.position_slide
                   
                   aux_end = aux_end > 12 ? 12: aux_end
                   let end = aux_start+aux_end
                 //  this.gallery_images=[]
                 this.from = aux_start+1
                 this.to = end
                     
                   //  this.gallery_images
                    for(let i = 0; i<this.gallery_images.length ;i++){
                      this.gallery_images[i].show=false
                   }

                   for(let i = aux_start; i<end ;i++){
                      this.gallery_images[i].show=true
                   }

                   if(this.gallery_images.length===0) {
                 this.flag=true
             }else{
                 this.flag=false
             }
            }
        },
        initialize_pagination(){
          /*  this.from=1
            this.to= this.gallery_images.length*/
             this.mount= this.gallery_images.length
        },
        async change_file_edit(all_files){

            let key=""
            this.add_gallery_loader=true

            try{

                if(this.title==="Backgrounds"){
    
                    for(let i=0;i<all_files.length;i++){
                        key=this.item.id+"/backgrounds/"+all_files[i].file.name
    
                        const result_filter=this.gallery_images.filter(el=>el.img===String(process.env.VUE_APP_URL_CLOUDFRONT+key))
                   
                        if(result_filter.length>0) throw new Error("Image with this name already exists")
    
        
                       const result= await AddImage(this.item.id,key,all_files[i].file)
                        const url = this.URL_CLOUDFRONT+key
    
                        this.item.backgrounds=result.data.updateEvent.backgrounds
        
                        this.gallery_images.push({title:all_files[i].file.name,img:url,show:false})
                        this.load_positions_slide()
                                       
                    }
    
              
                     this.add_gallery_loader=false 
    
    
                }else if(this.title==="Frames"){
    
                    for(let i=0;i<all_files.length;i++){
                    key=this.item.id+"/frames/"+all_files[i].file.name

                      const result_filter=this.gallery_images.filter(el=>el.img===String(process.env.VUE_APP_URL_CLOUDFRONT+key))
                   
                        if(result_filter.length>0) throw new Error("Image with this name already exists")
    
                    const result= await AddImage(this.item.id,key,all_files[i].file)
                    const url = this.URL_CLOUDFRONT+key
    
                    this.item.frames= result.data.updateEvent.frames
                    this.gallery_images.push({title:all_files[i].file.name,img:url,show:false})
                    this.load_positions_slide()
                    }
                    this.add_gallery_loader=false
    
                }else if(this.title==="Stickers"){
    
                    for(let i=0;i<all_files.length;i++){
    
                    key=this.item.id+"/stickers/"+all_files[i].file.name
                      const result_filter=this.gallery_images.filter(el=>el.img===String(process.env.VUE_APP_URL_CLOUDFRONT+key))
                   
                        if(result_filter.length>0) throw new Error("Image with this name already exists")
                    const result= await AddImage(this.item.id,key,all_files[i].file)
                    const url = this.URL_CLOUDFRONT+key
    
                    this.item.stickers= result.data.updateEvent.stickers
                    this.gallery_images.push({title:all_files[i].file.name,img:url,show:false})
                    this.load_positions_slide()
                    }
                    this.add_gallery_loader=false
    
                }
    
                this.flag=false
            }catch(error){
                 this.add_gallery_loader=false

                alert(error.message)

            }

        
        },
        open_dialog_create(){
         this.dialog_create=true
        },
close_modal_create(){
    this.dialog_create=false
},
        open_dialog(){
          this.dialog=true
        },
        close_modal(){ 
            this.dialog=false },
        async confirm_delete(value){
            this.loader=true
              let keysImagesToDelete=[]
              let imgs_remove_from_gallery=[]

          this.ex4.forEach(position=>{
              if(this.title === "Backgrounds"){
                  this.item.backgrounds.forEach(value=>{
                      if(this.gallery_images[position].img.search(value.key)!=-1){
                        //  console.log(this.gallery_images[position].img.split("."))
                          keysImagesToDelete.push(value.key)
                          imgs_remove_from_gallery.push(this.gallery_images[position].title)
                          //console.log(value.key)
                      }
   
                  })
              }else if(this.title === "Frames"){
                    this.item.frames.forEach(value=>{
                      if(this.gallery_images[position].img.search(value.key)!=-1){
                        //  console.log(this.gallery_images[position].img.split("."))
                          keysImagesToDelete.push(value.key)
                           imgs_remove_from_gallery.push(this.gallery_images[position].title)
                          //console.log(value.key)
                      }
   
                  })
              }else if(this.title === "Stickers"){
                    this.item.stickers.forEach(value=>{
                      if(this.gallery_images[position].img.search(value.key)!=-1){
                        //  console.log(this.gallery_images[position].img.split("."))
                          keysImagesToDelete.push(value.key)
                           imgs_remove_from_gallery.push(this.gallery_images[position].title)
                          //console.log(value.key)
                      }
   
                  })
              }
          })

          //console.log(keysImagesToDelete)
       
          for(let i=0;i<keysImagesToDelete.length;i++){
              await   DeleteImage(this.item.id,keysImagesToDelete[i])
          }

        
           
       
            this.gallery_images= this.gallery_images.filter(el=>!imgs_remove_from_gallery.includes(el.title))

                   
                    this.ex4=[]
                    this.text_select=this.select_all_text

                    if(this.gallery_images.length===0) this.flag=true

                    this.load_positions_slide()
          
                this.loader=false
               if(value) this.dialog=false
        
            
        },
   
       async download_images(){
     
                let urls=[]

            for(let i=0 ; i<this.gallery_images.length;i++){
                if( this.ex4.includes(i)){
                      const file=  await GetUrlImage(this.gallery_images[i].img.split(process.env.VUE_APP_URL_CLOUDFRONT)[1])
                   urls.push(file)
                }
             
            }
            let prefix=`${this.item.organization}-${this.item.name}-${this.title}`
           // console.log(prefix)
           this.add_gallery_loader=true
            downloadAndZip(urls,this.gallery_images.length,prefix,()=>{
               this.add_gallery_loader=false
            })

        },
        load_images_edit(){
            if(this.edit){
            this.images_edit.forEach(value=>{
                            this.gallery_images.push({title:value.title,img:value.url,show:false})
                        })
             if(this.gallery_images.length===0) {
                 this.flag=true
             }else{
                 this.flag=false
             }
            }

        },
        change_file(all_files){

            try{

    
            for(let i=0;i<all_files.length;i++){
                const result_filter=this.gallery_images.filter(el=>el.title===all_files[i].file.name)
                       
                     if(result_filter.length>0) throw new Error("Image with this name already exists")
        
                  this.gallery_images.push({title:all_files[i].file.name,img:all_files[i].url_img,show:true})
                this.files_images.push(all_files[i].file)
            }
    
            if(this.gallery_images.length>0){
                this.flag_error=false
            }
    
             this.$emit('update:images',this.files_images)
           
                this.flag=false
            }catch(error){

                alert(error.message)

            }

           
        },
        select_clear_all(){
            this.ex4=[]
            if(this.text_select===this.select_all_text){
                
                this.gallery_images.forEach((el,index)=>this.ex4.push(index))
                this.text_select=this.clear_all_text
            }else{
                this.ex4=[]
                this.text_select=this.select_all_text
            }
        },
        delete_items(){

        /*    Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                 
                }
                })*/

           // console.log(this.gallery_images.filter((el,index)=>!this.ex4.includes(index)))
            
            
        }
    },
    computed:{
        flag_error:{
            get(){
                return this.error_flag
            },
            set(value){
                this.$emit('update:error_flag',value)
            }
        },
        message_error:{
            get(){
                return this.error_message
            },
            set(value){
                this.$emit('update:error_message',value)
            }
        }

    }
    ,
    watch:{
        ex4:function(val){
              this.text_images_selecteds= `${val.length} ${this.title} have been selected from this page`
            if(val.length>0){
                this.disabled=false
            }else{
                this.disabled=true
            }

        }
    }

}
</script>
<style >

.personalize-checkbox .mdi.mdi-checkbox-blank-outline,
.personalize-checkbox .mdi.mdi-checkbox-marked{
    background-color: white;
    width:17px;
    height: 18px;
    border-radius: 4px;
}
</style>
