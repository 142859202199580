<template>
        <div class="event-font">
            {{text}}
        </div>
</template>

<script>

export default {
    name:'Title',
    props:["text"]
}
</script>
<style scoped>
 .event-font{
     font-size:20px;
     font-weight: bold;
     margin-top: 30px;
     margin-bottom: 30px;
 }
</style>