<template>
    <v-row>
        <v-col cols="3" v-for="index in mount" :key="index">
        <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="card"
    ></v-skeleton-loader>
    </v-col>
    </v-row>
</template>

<script>
export default {
    name:"SkeletonCardImage",
    props:["mount"]
}
</script>
