<template>
    <span @click="select" class="select-all">{{text}}</span>
</template>

<script>
export default {
    props:["text"],
    methods:{
        select(){
            this.$emit("select")
        }
    }
}
</script>

<style scoped>
.select-all:hover{
    cursor:pointer;
    text-decoration: underline;
}

.select-all{
    line-height: 35px;
}
</style>