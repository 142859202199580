/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      organization
      time_zone
      active_from {
        date
        time
      }
      active_until {
        date
        time
      }
      webhook_url
      skip_disclaimer
      social_networks
      default_caption
      final_message
      backgrounds {
        bucket
        region
        key
      }
      frames {
        bucket
        region
        key
      }
      stickers {
        bucket
        region
        key
      }
      deleted
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      organization
      time_zone
      active_from {
        date
        time
      }
      active_until {
        date
        time
      }
      webhook_url
      skip_disclaimer
      social_networks
      default_caption
      final_message
      backgrounds {
        bucket
        region
        key
      }
      frames {
        bucket
        region
        key
      }
      stickers {
        bucket
        region
        key
      }
      deleted
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      organization
      time_zone
      active_from {
        date
        time
      }
      active_until {
        date
        time
      }
      webhook_url
      skip_disclaimer
      social_networks
      default_caption
      final_message
      backgrounds {
        bucket
        region
        key
      }
      frames {
        bucket
        region
        key
      }
      stickers {
        bucket
        region
        key
      }
      deleted
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createPhotoSubmissions = /* GraphQL */ `
  mutation CreatePhotoSubmissions(
    $input: CreatePhotoSubmissionsInput!
    $condition: ModelPhotoSubmissionsConditionInput
  ) {
    createPhotoSubmissions(input: $input, condition: $condition) {
      id
      filename
      social_networks
      createdAt
      photo {
        bucket
        region
        key
      }
      eventID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updatePhotoSubmissions = /* GraphQL */ `
  mutation UpdatePhotoSubmissions(
    $input: UpdatePhotoSubmissionsInput!
    $condition: ModelPhotoSubmissionsConditionInput
  ) {
    updatePhotoSubmissions(input: $input, condition: $condition) {
      id
      filename
      social_networks
      createdAt
      photo {
        bucket
        region
        key
      }
      eventID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deletePhotoSubmissions = /* GraphQL */ `
  mutation DeletePhotoSubmissions(
    $input: DeletePhotoSubmissionsInput!
    $condition: ModelPhotoSubmissionsConditionInput
  ) {
    deletePhotoSubmissions(input: $input, condition: $condition) {
      id
      filename
      social_networks
      createdAt
      photo {
        bucket
        region
        key
      }
      eventID
      createdOn
      updatedOn
      owner
    }
  }
`;
