import awsexports from "../../../../aws-exports"

export class PhotoSubmissions{
    readonly id:string
    readonly filename:string
    readonly file:any
    readonly photo:{bucket:string,region:string,key:string}
    readonly eventID:string

    constructor(params:{id:string,filename:string,file:any,eventID:string}){
        this.id = params.id
        this.filename= params.filename
        this.photo={
            bucket:awsexports.aws_user_files_s3_bucket,
            region:awsexports.aws_user_files_s3_bucket_region,
            key:`${params.eventID}/photosubmission/${params.filename}`
        }
        this.eventID= params.eventID
        this.file = params.file
    }


}