<template>
<div>
  <div class="font-label">Date</div>
            <div>
                 <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="data"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="data"
                            placeholder="yyyy mm dd"
                            outlined
                            :error-messages="error"
                           dense
                            readonly
                            @focusout="focusout"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="data"
                        no-title
                        scrollable
                        >
                       <v-spacer></v-spacer>
                   <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save('')"
                        >
                            Clear
                        </v-btn>
                        <!--   <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(data)"
                        >
                            OK
                        </v-btn>-->
                        </v-date-picker>
                    </v-menu>
                   
            </div>
</div>
  
</template>

<script>
export default {
    props:["value","error_message","obligatory"],
    data(){
        return {
            menu2:"",
             date: ""
        }
    },
    methods:{
        focusout(){
            if(this.data.length<1 && this.obligatory){
                this.error="the field date is obligatory"
            }else{
                this.error=""
            }
        }
    },
    computed:{
        data:{
            get(){
                return this.value
            },
            set(value){
                this.$refs.menu.save(value)
                this.$emit("update:value",value)
                return value
            }
        },
        error:{
            get(){
                return this.error_message
            },
            set(value){
                this.$emit('update:error_message',value)
            }
        }
    },
    watch:{
        data:function(value){
            if(typeof value === "undefined" ){
                if(this.obligatory){
                      this.error="the field date is obligatory"
                }else{
                    this.error=""
                }
                return
            }
 if(value.length<1 && this.obligatory){
                this.error="the field date is obligatory"
            }else{
                this.error=""
            }
        }
    }
    
}
</script>

<style scoped>
.font-label{
    font-size: 16px;
    margin-bottom: 8px;

}

</style>