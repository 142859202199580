<template>
<div style="margin-top:100px">
     <div style="font-weight:bold;font-size:25px">Photo Submissions</div>
    <SubmissionSharedOn v-if="visible_shared_metadata" :metadata="shared_metadata"/>
    <div style="margin:10px 0;">
   <v-row >
        <v-col cols="auto" > <LabelHover @select="select"  :text="text_select" /> </v-col>
        <v-col cols="auto"><v-btn  color="#FFAD32" :disabled="disabled_buttons" @click="open_dialog"><span style="color:white">Delete</span></v-btn></v-col>
        <v-col cols="auto"><v-btn  color="#6D8794" @click="download_images" :disabled="disabled_buttons"><span style="color:white">Download</span></v-btn></v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
            <FileImageInput @onChangeFile="change_file" addText="Photo" />
      </v-col>
      <v-col cols="3">
          <Pagination :from="from" :to="to"  :mount="mount" @next="next" @back="back" />
      </v-col>
    </v-row>
    </div>
   
    <Divisor/>
    <ImagesSelecteds :text_images_selecteds="text_images_selecteds"/>
    <div style="margin-top:40px;">
         <Skeletoncardimage v-show="loading" :mount="mount" />
         <v-row  v-show="!loading">
             
         <v-col cols="3"  v-for="(item,index) of photo_images" :key="item.id">
             <div style="width:100%">

                <div>
                     <v-img :eager="true" ref="preview" width="100%" :src="item.url" ></v-img> 
                </div>
                <div style="display:flex;justify-content:flex-end;margin-top:-46px">
                    <div class="photo-submission-image">
                        
                        <v-checkbox
                        style="background-color:white;"
                        v-model="ex4"
                        color="#EEA22F"
                        :value="index"
                        hide-details
                        />
                    </div>
                    
                </div>
                <div style="font-size:13px; margin-top:10px">
                    <div > <span style="font-weight:bold">Created:</span> {{create_date(item.createdOn)}}</div>
                    <div><span style="font-weight:bold">Shared on:</span></div> 
                    <div style="font-size:12px">
                         <span v-if="item.social_networks.length>0"> {{item.social_networks[0]}} </span> 
                         <span v-if="item.social_networks.length>1">- {{item.social_networks[1]}} </span>  
                         <span v-if="item.social_networks.length>2">- {{item.social_networks[2]}} </span>
                          <span v-if="item.social_networks.length>3"> - {{item.social_networks[3]}}</span> </div> 
                </div>

            </div>

         </v-col>
       
        
    </v-row>

    </div>
     <DialogAlert :load="loader" @confirm="confirm_delete" @cancel="close_modal" v-show="dialog"/>
     <Loader v-show="loader_full" />
</div>

</template>
<script>
import SubmissionSharedOn from "../molecules/SubmissionSharedOn.vue"
import LabelHover from "@/app/components/atoms/LabelHover.vue"
import Loader from "@/app/components/atoms/Loader.vue"
import FileImageInput from "@/app/components/molecules/FileImageInput.vue"
import Pagination from "@/app/components/molecules/Pagination.vue"
import ImagesSelecteds from "@/app/components/molecules/ImagesSelecteds.vue"
import Divisor from "@/app/components/atoms/Divisor.vue"
import SearchPhotosByEvent from "../../../context/client/core/interactors/SearchPhotosByEvent"
import SearchPhotosByEventBefore from "../../../context/client/core/interactors/SearchPhotosByEvent/indexEventBefore"
import GetUrlImage from "../../../context/admin/core/interactors/GetUrlImage"
import Skeletoncardimage from "../atoms/SkeletonCardImage.vue"
import {downloadAndZip} from "@/app/helpers/donwloadZip"
import DeletePhotoSubmission from "../../../context/client/core/interactors/DeletePhotoSubmission"
import CreatePhotoSubmission from "../../../context/client/core/interactors/CreatePhotoSubmission"
import DialogAlert from "./DialogAlert.vue"
//import PaperImage from "@/app/components/molecules/PaperImage.vue"

export default {
    props:["metadata", "edit"],
    name:"PhotoSubmissionss",
    components:{
        SubmissionSharedOn,
        LabelHover,
        FileImageInput,
        Pagination,
        ImagesSelecteds,
        Divisor,
        Skeletoncardimage,
        DialogAlert,
        Loader
    },
    data(){
        return{
            ex4:[],
            text_select:"Select all",
            from:0,
            to:0,
            position_slide:0,
            photo_images:[],
            loading:false,
            mount:0,
            token_pointers:["","",""],
            token_history:[""],
            shared_metadata:{},
            visible_shared_metadata:false,
            disabled_buttons:true,
            clear_all_text:"Unselect all",
            text_images_selecteds:"",
            loader:false,
            dialog:false,
            loader_full:false,
            URL_CLOUDFRONT:process.env.VUE_APP_URL_CLOUDFRONT,
            next_token:"",
            //count slides
            sliders_cant:0,
        }
        
    },
    async mounted(){
         this.text_images_selecteds= `0 photos have been selected from this page`
        this.shared_metadata=this.metadata
        this.visible_shared_metadata=true
      
 
        const token=  await this.load_image_pagination(this.next_token)
        this.token_history.push(token)
            /*   this.token_pointers.push(token)
          this.token_pointers.shift() */
      
    },
    methods:{
        async change_file(all_files){
            this.loader_full=true

            for(const item of all_files){
                await CreatePhotoSubmission({file:item.file,eventID:this.$route.params.id})
            }

               await this.load_image_pagination(this.next_token)
          /*     this.token_pointers.push(token)
          this.token_pointers.shift() */
          this.loader_full=false
          this.ex4=[]

   

        },
       async confirm_delete(){
          this.loader=true
             // let keysImagesToDelete=[]
              let imgs_remove_from_gallery=[]
             
             this.ex4.forEach(position =>{
                  imgs_remove_from_gallery.push(this.photo_images[position])
             })

            

             for( const item in imgs_remove_from_gallery ){
             await DeletePhotoSubmission(imgs_remove_from_gallery[item].id,imgs_remove_from_gallery[item].photo.key)
             }

        
         //   this.ex4=this.ex4.filter(()=>false)
         setTimeout(async ()=>{
              await this.load_image_pagination(this.token_history[this.token_history.length-2])
             /*  this.token_pointers.push(token)
          this.token_pointers.shift() */
           this.loader=false
             this.dialog=false
               this.ex4=[]

         },3000)

            

        } ,
        close_modal(){
            this.dialog=false
        },
        open_dialog(){
            this.dialog=true
        },
      async  download_images(){

                let urls=[]

            for(let i=0 ; i<this.photo_images.length;i++){
                if( this.ex4.includes(i)){
                   const file=  await GetUrlImage(this.photo_images[i].url.split(process.env.VUE_APP_URL_CLOUDFRONT)[1])
                   urls.push(file)
                }
             
            }

             let prefix=`photo-submission`

              downloadAndZip(urls,this.photo_images.length,prefix,()=>{
              // this.add_gallery_loader=false
            })

        },
        select(){
            if(this.text_select!== this.clear_all_text){
                this.photo_images.forEach((el,index)=>this.ex4.push(index))
                this.text_select=this.clear_all_text

            }else{
                this.ex4=[]
                this.text_select="Select all"
            }

        },
        async next(){
            // if(this.position_slide<this.sliders_cant-1){
                
            //    this.position_slide=this.position_slide+1
            //    this.load_positions_slide()
            // }
            if(this.to !== this.mount){
                this.position_slide= this.position_slide+1 
                this.config_value_pagination()
                this.mount = (this.to-this.from)+1
                const token= await this.load_image_pagination(this.token_history[this.token_history.length-1])
                this.token_history.push(token)
               /*  this.token_pointers.push(token)
              this.token_pointers.shift() */
              console.log("pinter",token)
            }

        },
        async back(){
            // if(this.position_slide>0){
            //    this.position_slide=this.position_slide-1
            //    this.load_positions_slide()
            // }
           if(this.from!==1){

               this.position_slide= (this.position_slide - 1)<0?0:this.position_slide-1
               this.config_value_pagination()
               this.mount = (this.to-this.from)+1
                await  this.load_image_pagination(this.token_history[this.token_history.length-3])
                this.token_history.pop()
           }
            //  this.token_pointers.splice(0,0,token)
          //this.token_pointers.pop()
        },
        async load_image_pagination(token) {
            this.loading = true
            const dataBefore = await SearchPhotosByEventBefore(this.$route.params.id, token)

            const data = await SearchPhotosByEvent(this.$route.params.id, token)

            /* this.token_pointers.push(data.nextToken)
            this.token_pointers.shift() */

            this.mount = dataBefore.scannedCount
            this.config_value_pagination()

            this.photo_images = []
            for (const item of data.items) {
                const value = this.URL_CLOUDFRONT + item.photo.key
                this.photo_images.push({ ...item, url: value })
            }
            this.loading = false

            return data.nextToken
        },
        config_value_pagination() {
            this.from = (this.position_slide * 12) + 1
            let aux_end = this.mount - 12 * this.position_slide
            console.log('aux_end', aux_end)

            aux_end = aux_end > 12 ? 12 : aux_end

            let end = (this.position_slide * 12) + aux_end
            this.to = end

        },
        create_date(value){
            const date =  new Date(value)
            let hours_real=""
            if(date.getHours()>12){
              hours_real= parseInt(date.getHours())-12+":"+date.getMinutes()+" pm"
            }else{
                hours_real= parseInt(date.getHours())+":"+date.getMinutes()+" am"
            }

            return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+" at "+hours_real
        }
    },
    watch:{
        ex4:function(value){
            this.text_images_selecteds= `${value.length} photos have been selected from this page`
            if(value.length>0){
                this.disabled_buttons=false
            }else{
                this.disabled_buttons=true

            }
        }
    }
    
}
</script>

<style>

.photo-submission-image .mdi.mdi-checkbox-blank-outline,
.photo-submission-image .mdi.mdi-checkbox-marked{
    background-color: white;
    width:18px;
    height: 18px;
    border-radius: 5px;
}
</style>