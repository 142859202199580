<template>
<div>
    <TopBar1/>
    <Content>
    <Title text="Create photo booth"/>
    <FormEvent/>
    </Content>
    <Footer/>
</div>

</template>

<script>
import Title from "@/app/components/atoms/Title.vue"
// import TopBar from "@/app/components/templates/TopBar.vue"
import TopBar1 from "@/app/components/templates/TopBar1.vue"
import FormEvent from "@/app/components/organisms/FormEvent.vue"
import Content from "@/app/components/atoms/Content.vue"
import Footer from '@/app/components/templates/Footer.vue';
export default {
    name:"CreateEvent",
    components:{
        TopBar1,
        Title,
        FormEvent,
        Content,
        Footer
    }
}
</script>
