interface IFrame{
    bucket:string,
    region:string,
    key:string
}


export class Frame  implements IFrame{

    readonly bucket:string
    readonly region:string
    readonly key:string

    constructor(params:IFrame){
        this.bucket = params.bucket
        this.region = params.region
        this.key = params.key
    }

    static prepareImageKeyForStorage(params:{filename:string,key:string}){

        return params.key+"/frames/"+params.filename

    }

    static verifyArrayIsGreaterThanOne(objecto:any[]){
        if(objecto.length<1){
            throw new Error("Frame should contain at least one image in array")
        }

    }


    static create(params:IFrame){
        return new Frame(params)

    }

}