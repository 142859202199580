<template>
  <div class="logo-header">
    <Content>
      <v-row justify="space-between" class="header-nav">
        <v-col cols="4">
          <div class="logo">
            <span class="house" @click="go_home">
              <img src="https://lirp.cdn-website.com/e7a60b14/dms3rep/multi/opt/Simply+Photo+Booths+Logo+-+Horizontal+Lockup+-+White-1920w.png" height="44px" width="283px">
            </span>
          </div>
        </v-col>

        <v-col cols="auto" style="text-align: center; font-size: 23px"
          ></v-col
        >
        <v-col cols="2">
          <ul class="list" v-show="controls">
            <li class="list-li"><span class="logout" @click="go_home" > Home </span></li>
            <li class="list-li"><span class="logout" @click="signOut"> Logout </span></li>
          </ul>
        </v-col>
      </v-row>
    </Content>
  </div>
</template>

<script>
import Content from "@/app/components/atoms/Content"
import { Auth } from 'aws-amplify';

export default {
  props:{controls:{
    default:true,
    type:Boolean
  }},
  name: 'TopBar1',
  components:{Content},
    methods:{
        go_home(){
            this.$router.push('/').catch(() => {});
        },
        async signOut() {
            try {
                await Auth.signOut();
                window.location.href="/"
            } catch (error) {
                console.log('error signing out: ', error);
            }
        }
    }
}
</script>
<style scoped>
.list {
    display: flex;
    grid-gap: 32px;
    list-style: none;
    color: #fff;
}
.house:hover{
    cursor:pointer
}
.list-li:hover {
  cursor: pointer;
  text-decoration: underline;
}
.logout{
    line-height: 40px;
    font-size:18px;
    cursor:pointer
}
.house:hover{
    cursor:pointer
}
.logo {
	height: 44px;
}
.logo-header {
  background: url(/gradient_about.jpg) 50% / cover;
  width: 100%;
  font-size: 18px!important;
  height: 105px;
  background-size: cover;
}

.header-nav {
  /* padding: 0px 100px; */
  align-items: center;
  height: 105px;
}
</style>
