import { PhotoSubmissions } from "../../entities/PhotoSubmission";
import { PhotoSubmissionRepository } from "../../repository/PhotoSubmissionRepository";
import { nanoid } from "nanoid"
export const CreatePhotoSubmission = (
    repository:PhotoSubmissionRepository
    ) => async (params:{file:any,eventID:string})=>{

    const  photoSubmissions = new PhotoSubmissions({
                                            id:nanoid(),
                                            filename:params.file.name,
                                            file:params.file,
                                            eventID:params.eventID
                                        })

                   return    await repository.create(photoSubmissions)

}