import { PhotoSubmissionRepository } from "../../repository/PhotoSubmissionRepository";

export const SearchPhotosByEvent = (repository:PhotoSubmissionRepository) => async(id:string,next_token:string)=>{

    return await repository.search(id,next_token)

}

export const SearchPhotosByEventBefore = (repository:PhotoSubmissionRepository) => async(id:string,next_token:string)=>{

    return await repository.searchBefore(id,next_token)

}