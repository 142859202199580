<template>
    
 <div>
     <v-row>
         <v-col cols="6" class="numbers alinear"> {{from}} - {{to}} of {{mount}}</v-col>
         <v-col cols="6">
             <v-row>
                 <v-col cols="auto" class="alinear">
                      <v-icon class="my-pointer" @click="back">mdi-chevron-left</v-icon>
                 </v-col>
                 <v-spacer/>
                 <v-col cols="auto" class="alinear">
                         <v-icon class="my-pointer" @click="next">mdi-chevron-right</v-icon>
                 </v-col>

             </v-row>
            
        </v-col>
     </v-row>
     
 </div>

</template>

<script>
export default {
    props:["from","to","mount"],

    data(){
        return {

        }
    },
    methods:{
        next(){
            this.$emit("next")
        },
        back(){
            this.$emit("back")
        }
    }
}
</script>

<style scoped>

.numbers{
    text-align: center;
   
}

.my-pointer{
    cursor: pointer;
}

.alinear{
 line-height: 35px;
}
</style>