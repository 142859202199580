import { Background } from "./Background"
import { Frame } from "./Frame"
import { Sticker } from "./Sticker"
import { nanoid } from 'nanoid'

type DateTime={
    date:string,
    time:string
}

interface IEvent{
     name:string
     organization:string
     time_zone:string
     active_from:DateTime
     active_until:DateTime
     webhookUrl: string
     default_caption:string
     final_message:string
     social_networks:string[]
     disclaimer:boolean
     /*backgrounds:Background[]
     frames:Frame[]
     stickers:Sticker[]*/
}

export class EventDomain implements IEvent{
    readonly id:string
    readonly name:string
    readonly organization:string
    readonly time_zone:string
    readonly active_from:DateTime
    readonly active_until:DateTime
    readonly webhookUrl: string
    readonly disclaimer: boolean
    readonly default_caption:string
    readonly final_message: string 
    readonly backgrounds:Background[]=[]
    readonly frames:Frame[]=[]
    readonly stickers:Sticker[]=[]
    readonly social_networks:string[]=[]
    readonly missing_fields:string[]=[]
    static readonly social_networks_permits=["facebook","instagram","twitter","linkedin"]


    constructor(params:IEvent){

        this.id= nanoid()
        console.log("id--",this.id)
        this.name= this.evaluate_field(params.name,"name")
        this.organization= this.evaluate_field(params.organization,"organization")
        this.time_zone =this.evaluate_field(params.time_zone,"time_zone")
        this.evaluate_field(params.active_from.date,"active_from_date")
        this.evaluate_field(params.active_from.time,"active_from_time")
        this.active_from = params.active_from
        //policy - if  active until date dont have date , it wont expire. But if have date , it should have time too.
        if(params.active_until.date!==""){
            this.evaluate_field(params.active_until.date,"active_until_date")
            this.evaluate_field(params.active_until.time,"active_until_time")
        }
        this.active_until = params.active_until
        this.webhookUrl = this.evaluate_webhook(params.webhookUrl)
        this.default_caption = params.default_caption
        this.final_message = params.final_message
        this.social_networks= EventDomain.verifyIfSocialNetworksIsAvailable(params.social_networks)
        this.disclaimer = params.disclaimer
        
    }


    static verifyIfSocialNetworksIsAvailable(values:string[]){

        values.forEach(el=>{
              if(!EventDomain.social_networks_permits.includes(el))
              throw new Error(`the social network ${el} is no permit.`)
        })

        return values
    }

    evaluate_webhook(value:string){
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); 
    
             if(value.length>0){
                 if(!pattern.test(value)){
                   this.missing_fields.push("webhook")
                 }
             }
             return value
    }

     verifyArrayIsGreaterThanOne(params:{backgrounds:any[],frames:any[],stickers:any[]}){
          if(params.backgrounds.length<1){
              this.missing_fields.push("backgrounds")
          }

          if(params.frames.length<1){
              this.missing_fields.push("frames")
          }

          if(params.stickers.length<1){
              this.missing_fields.push("stickers")
          }
    }

 

    addBackground(background:Background){
        this.backgrounds.push(background)
    }

    addFrame(frame:Frame){
        this.frames.push(frame)
    }

    addStickers(sticker:Sticker){
        this.stickers.push(sticker)
    }

    evaluate_field(value:string,field:string){
        if(value.length<1){
            this.missing_fields.push(field)
        }
        return value
    }

    verify_if_have_missing_files(){
        if(this.missing_fields.length>0){
            throw this.missing_fields
        }
    }
   
  

    verify_Backgrounds_have_atleast_one_image_in_array(){
          if(this.backgrounds.length<1){
              throw new Error("Background should contain at least one image in array")
          }
    }


    verify_Frames_have_atleast_one_image_in_array(){
        if(this.frames.length<1){
            throw new Error("Frame should contain at least one image in array")
        }
    }

    verify_Stickers_have_atleast_one_image_in_array(){
        if(this.stickers.length<1){
            throw new Error("Sticker should contain at least one image in array")

        }
    }

    static create(params:IEvent){
        return new EventDomain(params)
    }

}