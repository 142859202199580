<template>
<div>
 <v-data-table
    :headers="headers"
    :items="desserts"
    :search="search"
    class="elevation-1"
    hide-default-footer
    :item-class="paintRowByDate"
    @click:row ="ClickedRow"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
  >
     <template v-slot:item.options="{item}">
       <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                   <v-btn
                    fab
                     
                     x-small
                     elevation="2"
                     class="ma-2 white--text"
                     v-bind="attrs"
                     v-on="on"
                    > 
                    <v-icon style="color:black;"> mdi-dots-horizontal </v-icon>
                    </v-btn>
                  </template>
                 
                     <v-list dense style="margin-top:5px">
                      <v-list-item-group
                        v-model="selectedItem"
                        >
                      
                            <v-list-item
                            v-for="(it, index) in items"
                            :key="index"
                            >
                           
                                
                            <v-list-item-title style="color: #2c3e50" v-text="it.title" @click="evaluate(item,index)"></v-list-item-title>
                         
                            </v-list-item>
                          
                           
                    </v-list-item-group>
                </v-list>

              
                 
               
               </v-menu>
    </template>
  </v-data-table>
    <v-snackbar
      v-model="snackbar"
    >
     Embebed Code Copied!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <Loader v-show="add_gallery_loader" />
    <textarea id="copypaste" style="position:fixed;bottom:-200px;left:0;z-index:1;"></textarea>
</div>
 
</template>

<script>
import searchAllEvents from "../../../context/admin/core/interactors/SearchAllEvents"
import GetListImages from "../../../context/admin/core/interactors/GetListImages"
import GetUrlImage from "../../../context/admin/core/interactors/GetUrlImage"
import deleteEvent from "../../../context/admin/core/interactors/DeleteEvent"
import {downloadAndZip} from "@/app/helpers/donwloadZip"
import Loader from '../atoms/Loader.vue'
export default {
  props:["search"],
  components:{
    Loader
  },
    data(){
        return {
          add_gallery_loader:false,
              sortBy: 'type',
              sortDesc: false,
              selectedItem:0,
              aux:0,
              snackbar:false,
              headers: [
          {
            text: 'Name',
            align: 'start',
            value: 'name',
          },
          { text: 'Organization', value: 'organization' },
          { text: 'Created', value: 'created' },
          { text: 'Active From', value: 'from' },
          { text: 'Active Until', value: 'until' },
          { text: '', value: 'options' }
        ],
        desserts:[],
            items:[
                {title:"View and edit photo booth"},
                {title:"Copy JavaScript embed code (most common)"},
                {title:"Copy iframe embed code"},
                {title:"Download All Photo submission"},
                {title:"Delete photo booth"}
            ]
        }
    },
    async mounted(){
      
        const result =    await searchAllEvents(localStorage.getItem("user"))
        console.log('result', result);

        result.forEach((value)=>{
          console.log('value', value);
           this.desserts.push({
             id:value.id,
             name:value.name,
             organization:value.organization,
             created:value.createdOn.split("T")[0]+" "+value.createdOn.split("T")[1].split(":")[0]+":"+value.createdOn.split("T")[1].split(":")[1],
             from:value.active_from.date+" "+value.active_from.time,
             until:value.active_until.date+" "+value.active_until.time,
             backgrounds:value.backgrounds,
             frames:value.frames,
             stickers:value.stickers,
             timezone:value.time_zone,
             type: 3
           })
        })
    },
    methods:{
      ClickedRow(item){
        item
      //  console.log(item)
      },
      paintRowByDate (item) {
        let cambioFecha = (fechaFin, fechaInic) => {
          return (fechaFin - fechaInic)/(1000*60*60*24);
        };
        const time = new Date();
        let utc;
        let nd;
        if(item.timezone == 'MT'){
          utc = new Date(time.getTime() - (120 * 60000));
        } else if(item.timezone == 'PT') {
          utc = new Date(time.getTime() - (180 * 60000));
        } else if(item.timezone == 'CT'){
          utc = new Date(time.getTime() - (60 * 60000)) ;
        } else {
          utc = new Date(time.getTime());
        }

        nd= utc.getTime();
      //  console.log('Math.round(cambioFecha(new Date(item.from).getTime(), nd))', Math.round(cambioFecha(new Date(item.from).getTime(), nd)));
      
        if(nd >= new Date(item.from).getTime() && (nd <= new Date(item.until).getTime() || item.until == " " || item.until == "")){
          item.type = 1;
          return 'clase-MT';
        }else if(Math.round(cambioFecha(new Date(item.from).getTime(), nd)) == 30){
          item.type = 2;
          return 'clase-PT';
        }else {
          item.type = 3;
          return '';
        }
      },
      async evaluate(item,index){
        if(index===0){
          this.$router.push('/edit/'+item.id)}
        else if(index===1){
          const value = `
          <div id="vpb">
              <virtual-photo-booth idevent=${item.id}></virtual-photo-booth>
            </div>
            &lt;link rel="stylesheet" href="${process.env.VUE_APP_URL_CLOUDFRONT}embedded/cropper.min.css"&gt; &lt;/link&gt;
            &lt;script src="${process.env.VUE_APP_URL_CLOUDFRONT}embedded/vue2.js"&gt; &lt;/script&gt;
            &lt;script src="${process.env.VUE_APP_URL_CLOUDFRONT}embedded/vpb.umd.js"&gt; &lt;/script&gt;
            &lt;script src="${process.env.VUE_APP_URL_CLOUDFRONT}embedded/vpb-instance.js"&gt; &lt;/script&gt;
          `
        const copypaste = document.getElementById("copypaste")
        copypaste.innerHTML= value
        copypaste.select()

        
        setTimeout(()=>{
          document.execCommand("copy")
        },500)

    
            navigator.clipboard.writeText(value)
          this.snackbar=true
          setTimeout(()=>{
             this.snackbar=false
          },2000)
        }
        else if(index ===2){
          navigator.clipboard.writeText(
            `<iframe 
       style="width:100%;height:715px"
          allow="microphone;camera;midi;encrypted-media;" 
          allowtransparency="true"
          frameborder="0"
          scrolling="no"   
       src="${process.env.VUE_APP_URL_CLOUDFRONT}embedded/vpb.html?idevent=${item.id}"></iframe>`
          )
          this.snackbar=true
          setTimeout(()=>{
             this.snackbar=false
          },2000)
        }
        else if(index === 3){

            this.add_gallery_loader=true

         const result =  await GetListImages(item.id+"/photosubmission/")
         
         let urls = []

         for(let i=0;i<result.length ; i++){
          const url = await GetUrlImage(result[i].key)
          urls.push(url)
         }
         const date = new Date()
      
        
        downloadAndZip(urls,urls.length,"photo-submission-"+date.getMilliseconds(),()=>{
      this.add_gallery_loader=false
        })

         
         
        }
        else if(index === 4){
          try{
            
            await deleteEvent(item)

            this.loadEventsAfterDelete()

          }catch(error){
                console.log(error.message)
          }
        }
      },
      async loadEventsAfterDelete(){
        const result =    await searchAllEvents(localStorage.getItem("user"))
        this.desserts=[]

        result.forEach((value)=>{
           this.desserts.push({
             id:value.id,
             name:value.name,
             organization:value.organization,
             created:value.createdOn.split("T")[0]+" "+value.createdOn.split("T")[1].split(":")[0]+":"+value.createdOn.split("T")[1].split(":")[1],
             from:value.active_from.date+" "+value.active_from.time,
             until:value.active_until.date+" "+value.active_until.time,
             backgrounds:value.backgrounds,
             frames:value.frames,
             stickers:value.stickers,
             timezone:value.time_zone,
             type: 3
           })
        })

        /*result.data.listEvents.items.forEach((value)=>{
           this.desserts.push({
             id:value.id,
             name:value.name,
             organization:value.organization,
             created:'09/20/2021',
             from:value.active_from.date+" "+value.active_from.time,
             until:value.active_until.date+" "+value.active_until.time,
           })
        })*/
      }
    }
}
</script>

<style>
.clase-MT{
  /* background-color: red; */
  background: rgba(41, 236, 27, 0.28);
}
.clase-PT{
  /* background-color: green; */
  background: rgba(92, 125, 207, 0.35);
}
</style>
