<template>
    <div>
         <Label :text="text"/>
            <v-select
          :items="items"
          item-text="text"
          item-value="value"
          dense
          @suspend="focusout"
          :value="value"
          :error-messages="error"
          outlined
          placeholder="select"
          @change="change_select"
        ></v-select>
    </div>
</template>


<script>
import Label from "@/app/components/atoms/Label.vue"

export default { 
    props:["text", "items","value","error_message"],
    components:{
        Label
    },
    methods:{
        change_select(value){

             this.$emit('update:value',value)

            console.log(value)

        },
         focusout(){
            if(this.value.length<1){
                this.error="the field time zone is obligatory"
            }else{
                this.error=""
            }
        }
    },
    computed:{
        error:{
            get(){
                return this.error_message
            },
            set(value){
                this.$emit('update:error_message',value)
            }
        }
    },
    watch:{

    }

}
</script>