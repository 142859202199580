import Vue from 'vue'
import VueRouter from 'vue-router'
import Events  from '../components/pages/Events.vue'
import CreateEvent  from '../components/pages/CreateEvent.vue' 
import EditEvent  from '../components/pages/EditEvent.vue' 
import EventClient  from '../components/pages/EventClient.vue' 
import Register  from '../components/pages/Register.vue' 
import SharedPhoto from '../components/pages/SharedPhoto.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
     name:"Events",
     component:Events
  },
  {
    path:'/create',
    name:"CreateEvent",
    component:CreateEvent
  },
  {
    path:'/edit/:id',
    name:"EditEvent",
    component:EditEvent
  },
  {
    path:'/event/:id',
    name:"client",
    component:EventClient
  },
  {
    path:'/register',
    name:"register",
    component:Register
  },
  {
    path:'/shared/:idPhoto',
    name:"shared",
    component:SharedPhoto
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
