/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:473605d2-ffa2-4dac-bd8e-afa26b54b458",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jcTKGPE8K",
    "aws_user_pools_web_client_id": "2p7nrv3d5r221ucm7eb9o2le0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://3bryxo64lzdo7nihn4sp7f4bhi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-le4po7g23bh45gxsn7rnf3lu3m",
    "aws_cloud_logic_custom": [
        {
            "name": "api83dd0e8a",
            "endpoint": "https://n1j4pum6t6.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "virtualphotobooth205425-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
