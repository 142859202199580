var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"hide-default-footer":"","item-class":_vm.paintRowByDate,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"click:row":_vm.ClickedRow,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"fab":"","x-small":"","elevation":"2"}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"black"}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"margin-top":"5px"},attrs:{"dense":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(it,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{staticStyle:{"color":"#2c3e50"},domProps:{"textContent":_vm._s(it.title)},on:{"click":function($event){return _vm.evaluate(item,index)}}})],1)}),1)],1)],1)]}}])}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Embebed Code Copied! ")]),_c('Loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.add_gallery_loader),expression:"add_gallery_loader"}]}),_c('textarea',{staticStyle:{"position":"fixed","bottom":"-200px","left":"0","z-index":"1"},attrs:{"id":"copypaste"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }