interface IBackground{
    bucket:string,
    region:string,
    key:string
}


export class Background  implements IBackground{

    readonly bucket:string
    readonly region:string
    readonly key:string

    constructor(params:IBackground){
        this.bucket = params.bucket
        this.region = params.region
        this.key = params.key
    }


    static prepareImageKeyForStorage(params:{filename:string,key:string}){

        return params.key+"/backgrounds/"+params.filename

    }

    static verifyArrayIsGreaterThanOne(objecto:any[]){
        if(objecto.length<1){
            throw new Error("Background should contain at least one image in array")
        }

    }

  
  



    static create(params:IBackground){
        return new Background(params)

    }

}