import { EventImageRepository } from "../../core/repository/EventImageRepository";
import {Storage, API, graphqlOperation } from 'aws-amplify';

export class EventImageSourceStorage implements EventImageRepository{
    async getListImagesByPrefix(prefix: string): Promise<any> {
       return await Storage.list(prefix)
    }
    async store(key:string, file: any): Promise<any> {
        return await Storage.put(key,file)
    }
    async searchImagesByIdEvent(key: string): Promise<any> {
        return await Storage.get(key)
    }

    

    async remove(key:string){

        return await Storage.remove(key)

    }
    
}