import { EventDomain } from "../../entities/EventDomain";
import { EventRepository } from "../../repository/EventRepository";


export const EditEvent = (repository:EventRepository) => async (id:string,params:{
    name:string,
    organization:string,
    active_from:{
        date:string,
        time:string
    },
    active_until:{
        date:string,
        time:string
    },
    default_caption:string,
    final_message:string,
    time_zone:string,
    webhookUrl:string,
    disclaimer:boolean,
    social_networks:string[]}) => {


        const entity= new EventDomain(params)
        entity.verify_if_have_missing_files()

       return await  repository.edit(id,entity)



}