import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

const download = url => {
  return fetch(url,{mode:"cors"}).then(resp => resp.blob());
};

const downloadByGroup = (urls, files_per_group=5) => {
  return Promise.map(
    urls, 
    async url => {
      return await download(url);
    },
    {concurrency: files_per_group}
  );
}

const exportZip =( blobs ,urls,prefix,callback)=> {
  const zip = JsZip();
  blobs.forEach((blob, i) => {
    let name= urls[i].split(".")[4].split("/")[4]
   let extention= urls[i].split(".")[5].split("?")[0] //every url comes from aws storage image, At postion 5 we found extention.
    zip.file(`${name}.${extention}`, blob);
  });
  zip.generateAsync({type: 'blob'}).then(zipFile => {
    const date = new Date();
    const currentDate= date.getFullYear()+"_"+date.getMonth()+"_"+date.getDate()
    const fileName = `${prefix}-${currentDate}.zip`;
    callback()
    return FileSaver.saveAs(zipFile, fileName);
  });
}

export const downloadAndZip = (urls,length,prefix,callback) => {
  return downloadByGroup(urls, length).then(blob=> exportZip(blob,urls,prefix,callback));
}