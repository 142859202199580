<template>
<div  style="font-size:18px;padding:30px 20px;height:100vh;border:2px solid rgba(243,225,145,1)">
    <v-row>
        <v-col cols="6" v-show="!show_message">
            <div style="color:white;">
                Your first name:
            </div>
            <div >
            <input class="input" 
            type="text" 
            style="outline:none !important;width:100%;padding-left:5px;border-bottom:1px solid white;color:white" 
            id="name">
            </div>
        </v-col>
        <v-col cols="6" v-show="!show_message">
            <div  style="color:white;">
                Your last name:
            </div>
            <div>
            <input class="input" type="text" style="outline:none !important;width:100%;padding-left:5px;border-bottom:1px solid white;color:white"  id="lastname">
            </div>
        </v-col>
        <v-col cols="6" v-show="!show_message">
            <div  style="color:white;">
                Your email address:
            </div>
            <div>
            <input class="input"  type="text" style="outline:none !important;width:100%;padding-left:5px;border-bottom:1px solid white;color:white" id="email">
            </div>
        </v-col>
        <v-col cols="6" v-show="!show_message">
            <div  style="color:white;">
                Your phone number:
            </div>
            <div>
            <input class="input"  type="text" style="outline:none !important;width:100%;padding-left:5px;border-bottom:1px solid white;color:white"  id="phone">
            </div>
        </v-col>
        <v-col cols="12" v-show="!show_message">
            <div  style="color:white;">
                Your organization's name:
            </div>
            <div>
            <input class="input"  type="text" style="outline:none !important;width:100%;padding-left:5px;border-bottom:1px solid white;color:white"  id="organization">
            </div>
        </v-col>
        <v-col cols="12" v-show="!show_message">
            <div  style="color:white;">
                The name of your event or first photo booth:
            </div>
            <div>
            <input class="input"  type="text" style="outline:none !important;width:100%;padding-left:5px;border-bottom:1px solid white;color:white"  id="event">
            </div>
        </v-col>
     
            
        
        <v-col cols="4" v-show="!show_message">
            <div style="height:30px"></div>
            <div class="button  flex items-center justify-center" @click="proccess_form">
                <div  v-show="!progress_show" style="font-weight:bold;font-size:15px">SIGN UP NOW</div> 
                 <v-progress-circular
                 v-show="progress_show"
                  :width="6"
           indeterminate
         color="#EEA22F"
        ></v-progress-circular>
            </div>
        </v-col>
        <v-col cols="12" v-show="show_message" style="transition:all  1s ease" >
             <div style="height:80px"></div>
             <div style="height:50px;font-size:13px;color:white" class=" flex items-center ">
                 <div>
                    <v-icon
                     small
                    color="#EEA22F"
                right>
                mdi-checkbox-marked-circle
                </v-icon
               >
                the organization  were successfully registered !!
                 </div>
             </div>
        </v-col>
        <v-col cols="12" v-show="show_message">
             <div style="height:30px"></div>
             <div style="height:50px;font-size:13px;color:white" class=" flex items-center ">
                 <div>
                    <v-icon
                     small
                    color="#EEA22F"
                right>
                mdi-checkbox-marked-circle
                </v-icon
               >
                Thank you for contacting us.
                  We will get back to you as soon as possible.
                 </div>
             </div>
        </v-col>
    </v-row>
     
</div>
</template>

<script>
export default {
    data(){
        return {
            progress_show:false,
            show_message:false,
            color_border:"#4B4B4B",
            color_form:"black"
        }
    },
       mounted(){
           
          const border =this.$route.query.border
          const color = this.$route.query.color
          if( typeof border !== "undefined"  ){
              this.color_border="#"+border
              console.log(this.$route.query)
          }
    
          if( typeof color !== "undefined" ){
              this.color_form=color
          }
          
          
         
       },
   methods:{
       proccess_form(){
           this.progress_show=true
            this.show_message=false

           setTimeout(()=>{
              this.progress_show=false
              this.show_message=true
           },3000)
       },
      onfocus_input(input_ref){
          document.getElementById(input_ref).style.border="1px solid "+this.color_form
          document.getElementById(input_ref).style.borderRadius="5px"
      },
      desfocus(input){
          document.getElementById(input).style.border="none"
          document.getElementById(input).style.borderBottom="1px solid " + this.color_form
          document.getElementById(input).style.borderRadius="0px"
      }
   },
    
}
</script>
<style scoped>
 .button{
     color:white;
     border:1px solid white;
     height:50px;
     cursor: pointer;
     width:200px;
     font-family: roboto,sans-serif;

 }

 .input:focus{
     outline:none;
          border:1px solid white;
          border-radius: 2px;
 }

 .button:hover{
     background-color:white;
     color:black;
 }
</style>