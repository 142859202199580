<template>
    <div class="title_gallery">{{title}}</div>
</template>
<script>
export default {
    props:["title"]
}
</script>
<style scoped>
.title_gallery{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size:18px;
    font-weight: bold;
    border-bottom: 1.5px solid black;
}


</style>